var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center editInline w-100 pt-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.categoryName),expression:"categoryName"}],staticClass:"editInline__input form-control",attrs:{"type":"text"},domProps:{"value":(_vm.categoryName)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.rename.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}],"input":function($event){if($event.target.composing){ return; }_vm.categoryName=$event.target.value}}}),_c('div',{staticClass:"d-flex"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        autoHide: false,
        trigger: 'click hover',
        content: _vm.$t('label.save'),
      }),expression:"{\n        autoHide: false,\n        trigger: 'click hover',\n        content: $t('label.save'),\n      }"}],staticClass:"editInline__icon px-2",class:{ 'btn-progress': _vm.loading },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rename.apply(null, arguments)}}},[_c('CheckSVG',{staticClass:"w-13"})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        autoHide: false,
        trigger: 'click hover',
        content: _vm.$t('label.cancel'),
      }),expression:"{\n        autoHide: false,\n        trigger: 'click hover',\n        content: $t('label.cancel'),\n      }"}],staticClass:"editInline__icon px-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_c('TimesSVG',{staticClass:"w-10"})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }