<template>
  <div>
    <Navigator
      v-if="hasCategories"
      data-test="navigator"
      :categories="categories"
    />
    <Discount />
    <div v-if="hasCategories" data-test="menu">
      <Category
        v-for="(category, index) in categories"
        :id="`category${index}`"
        :key="index"
        :category="category"
        @openItem="openItem"
      />
    </div>
    <div
      v-if="!hasCategories"
      data-test="menu-empty"
      class="text-center px-3 py-5 f-size-20"
    >
      {{ $t("label.your_menu_is_empty") }}
    </div>
  </div>
</template>

<script>
import Category from "./Category.vue";
import Discount from "./Discount.vue";
import Navigator from "./Navigator.vue";

export default {
  name: "Menu",
  components: {
    Category,
    Discount,
    Navigator,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasCategories() {
      return this.categories.length > 0;
    },
  },
  methods: {
    openItem(itemId) {
      this.$emit("openItem", itemId);
    },
  },
};
</script>
