<template>
  <LoadingZone :loading="loading" class="p-4">
    <h5 class="text-center bold mb-4">
      {{ $t("c.items.printing_groups_list.add_printing_group") }}
      <span class="text-primary">
        {{ item.name }}
      </span>
    </h5>
    <input
      v-if="availableLists.length"
      v-model="search"
      type="text"
      class="form-control mb-3"
      :placeholder="
        $t('message.search_for_x_followed_by_ellipses', {
          search: $t('c.items.printing_groups_list.search_printing_groups'),
        })
      "
    />
    <ul class="p-0 m-0" style="max-height: calc(100vh - 320px); overflow: auto">
      <li
        v-for="(list, index) in filteredLists"
        :key="list.id"
        class="customCheckbox border-bottom"
      >
        <input
          :id="index"
          v-model="addGroups"
          :value="list"
          type="checkbox"
          class="customCheckbox__input"
        />
        <label :for="index" class="customCheckbox__text pt-3 pb-2">
          <span class="customCheckbox__square"></span>
          <div class="row m-0">
            <div class="col">
              <span
                class="d-flex flex-column"
                :title="createdAtLabel(list.createdAt)"
              >
                {{ list.name }}
                <small>{{ list.outletName }}</small>
              </span>
            </div>
          </div>
        </label>
      </li>
    </ul>
    <div v-if="availableLists.length && !filteredLists.length" class="my-5">
      <p class="text-center">
        {{
          $t(
            "c.items.printing_groups_list.there_is_no_printing_groups_matching"
          )
        }}
      </p>
    </div>
    <div v-if="!availableLists.length" class="mt-5 mb-4">
      <p class="text-center">
        {{
          $t(
            "c.items.printing_groups_list.there_is_no_printing_groups_available"
          )
        }}
      </p>
    </div>
    <div class="clearfix mt-4">
      <button class="btn btn-light" @click="$emit('close')">
        {{ $t("label.cancel") }}
      </button>
      <button
        class="float-right btn btn-primary"
        :class="{ 'btn-progress': adding }"
        :disabled="!addGroups.length"
        @click="save"
      >
        {{ $tc("c.items.printing_groups_list.add_x_groups", addGroups.length) }}
      </button>
    </div>
  </LoadingZone>
</template>

<script>
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import { isEmpty, is } from "ramda";
import { date } from "@/utils/filters";
import { Toast } from "@/design-system";
import { fetchPrintingGroups } from "@/v2/repo/fetch-printing-groups";
import { attachPrintingGroups } from "@/v2/repo/attach-printing-groups";

export default {
  name: "PrintingGroupList",
  components: {
    LoadingZone,
  },
  props: {
    updatePrintingGroups: {
      type: Function,
      default: () => {},
    },
    excludeLists: {
      type: Array,
      default: null,
    },
    businessId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    search: "",
    adding: false,
    loading: false,
    lists: [],
    addGroups: [],
  }),
  computed: {
    availableLists() {
      if (!is(Array, this.excludeLists)) return this.lists;

      return this.lists.filter((obj) => !this.excludeLists.includes(obj.id));
    },
    filteredLists() {
      if (isEmpty(this.search)) return this.availableLists;

      const query = this.search.trim().toLowerCase();
      return this.availableLists.filter((obj) =>
        obj.name.toLowerCase().includes(query)
      );
    },
  },
  created() {
    this.fetchGroups();
  },
  methods: {
    createdAtLabel(createdAt) {
      if (!createdAt) return;

      return `${this.$t("label.created_at")}: ${date(
        createdAt,
        "MMM DD, YYYY - h:mm A"
      )}`;
    },

    async fetchGroups() {
      this.loading = true;
      try {
        this.lists = await fetchPrintingGroups({ businessId: this.businessId });
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.adding = true;

      try {
        await attachPrintingGroups({
          businessId: this.businessId,
          groupIds: this.addGroups.map((group) => group.id),
          itemId: this.item.id,
        });

        this.updatePrintingGroups();
        this.$emit("close");

        new Toast().create({
          type: "success",
          text: this.$t("message.records_linked"),
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.adding = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
