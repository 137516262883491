import Api from "./index";
import ModifierListConstructor from "@/constructors/ModifierList";
import ItemConstructor from "@/constructors/Item";
import PaginationConstructor from "@/constructors/Pagination";
import { map } from "ramda";

export default {
  /**
   * Get list of modifier lists.
   *
   * @param {String} businessId
   * @param {Object} params
   * @returns {{ data: Array<ModifierListConstructor>, pagination: PaginationConstructor }}
   */
  async get(businessId, params = {}) {
    params = ModifierListConstructor.toFilterPayload(params);

    const res = await Api.get(
      `/api/v3/businesses/${businessId}/modifier-lists`,
      {
        params,
      }
    );

    return {
      data: res.data.map((obj) => new ModifierListConstructor(obj)),
      pagination: new PaginationConstructor(res.meta),
    };
  },

  /**
   * Fetch a modifier list.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @param {Object} params
   * @returns {ModifierListConstructor}
   */
  async find(businessId, modifierListId, params = {}) {
    const res = await Api.get(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}`,
      {
        params,
      }
    );

    return new ModifierListConstructor(res.data);
  },

  /**
   * Create a modifier list.
   *
   * @param {String} businessId
   * @param {Object} payload
   * @returns {ModifierListConstructor} Returns the created modifier list
   */
  async create(businessId, payload) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/modifier-lists`,
      payload
    );

    return new ModifierListConstructor(res.data);
  },

  /**
   * Update the given modifier list.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @param {Object} payload
   * @returns {ModifierListConstructor} Returns the updated modifier list
   */
  async update(businessId, modifierListId, payload) {
    const res = await Api.put(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}`,
      payload
    );

    return new ModifierListConstructor(res.data);
  },

  /**
   * Delete a modifier list.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @returns {void}
   */
  async destroy(businessId, modifierListId) {
    await Api.delete(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}`
    );
  },

  /**
   * Attach a list of modifiers to the given modifier list.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @param {Array<Number>} modifiersId
   * @returns {ModifierListConstructor} Modifier list with its items loaded.
   */
  async attachModifiers(businessId, modifierListId, modifiersId) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}/attach-items`,
      { item_ids: modifiersId }
    );

    return new ModifierListConstructor(res.data);
  },

  /**
   * Detach a list of modifier from the given modifier list.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @param {Array<Number>} modifiersId
   * @returns {ModifierListConstructor} Modifier list with its items loaded.
   */
  async detachModifiers(businessId, modifierListId, modifiersId) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}/detach-items`,
      { item_ids: modifiersId }
    );

    return new ModifierListConstructor(res.data);
  },

  /**
   * Override modifier price.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @param {{ modifier_id: String, new_amount: Number }} payload
   * @returns {ModifierListConstructor} Modifier list with its items loaded.
   */
  async overrideModifierPrice(businessId, modifierListId, payload) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}/override-price`,
      payload
    );

    return new ModifierListConstructor(res.data);
  },

  /**
   * Sort modifiers from the given modifier list.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @param {Array<Number>} modifiersId Ordered modifier of modifier list ids
   * @returns {void}
   */
  async sortModifiers(businessId, modifierListId, modifiersId) {
    await Api.put(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}/reorder-items`,
      {
        ordered_items_ids: modifiersId,
      }
    );
  },

  /**
   * Get list of items attached in modifier list.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @param {Object} params
   * @returns {{data: Array<ItemConstructor>}}
   */
  async getItems(businessId, modifierListId) {
    const res = await Api.get(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}/items`
    );

    return map((obj) => new ItemConstructor(obj), res.data);
  },

  /**
   * Attach list of items to a modifier list.
   *
   * @param {String} businessId
   * @param {String} modifierListId
   * @param {Array<String>} itemIds
   * @returns {ItemConstructor} Item with all its resources loaded
   */
  async attachItems(businessId, modifierListId, itemIds) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/modifier-lists/${modifierListId}/attach-to-items`,
      { items_ids: itemIds }
    );

    return new ItemConstructor(res.data);
  },
};
