<template>
  <ul class="discount-box">
    <li v-for="index in 2" :key="index" class="discount-box__item">
      <PercentageSVG class="discount-box__icon" />
      <div class="pl-2">
        <p class="f-size-12 bold m-0">{{ index }}0% OFF</p>
        <span class="discount-box__shadow"></span>
      </div>
    </li>
  </ul>
</template>

<script>
import PercentageSVG from "@/assets/svg/icons/percentage.svg";

export default {
  name: "Discount",
  components: {
    PercentageSVG,
  },
};
</script>

<style lang="scss" scoped>
.discount-box {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 1.5rem 1rem 0;

  &__item {
    display: flex;
    align-items: center;
    flex: 1 0 calc(50% - 0.5rem);
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: solid 1px var(--color-brand-primary);

    &:last-child {
      margin: 0;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    fill: var(--color-brand-primary);
  }

  &__shadow {
    display: block;
    width: 90%;
    height: 6px;
    margin: 3px 0;
    border-radius: 30px;
    background: #d8d8d8;
  }
}
</style>
