import { http } from "../../http";
import { IRepoErrors } from "../../errors";

export class SetMenuDefaultError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: SetMenuDefault");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function setMenuDefault(
  businessId: string,
  menuId: string
): Promise<boolean> {
  try {
    await http.put(
      `/api/v3/businesses/${businessId}/menus/${menuId}/set-default`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new SetMenuDefaultError(error);
  }

  return true;
}
