<template>
  <div class="d-flex bg-white">
    <a
      href="#"
      class="pl-2 p_top_20 pr-3"
      @click.prevent="showModifiers = !showModifiers"
    >
      <AngleRightSVG
        class="w-8 fill_muted"
        :class="{ rotateDown: showModifiers }"
      />
    </a>
    <div class="w-100 border-top box-shadow-bottom">
      <div class="d-flex justify-content-between">
        <div v-if="!hasMenuIntegration" class="moveHandle pl-0 p_top_18">
          <LineHeightSVG class="w-15" />
        </div>
        <div class="py-3 w-100">
          <div class="d-flex justify-content-between">
            <span class="w-100 py-1 minw-100">{{ list.name }}</span>
            <div v-if="!editMode" class="editInline minw-200">
              <a
                href="#"
                class="text-dark d-flex align-items-center text-decoration-none editInline__link p-1"
                @click.prevent="goToEdit()"
              >
                <small class="d-inline-block mr-2 editInline__text">
                  <strong>{{ $t("label.type") }}:</strong>
                  {{ list.selectionType | cfStringBeautify }}
                </small>
                <small
                  v-if="list.minSelectedModifiers"
                  class="d-inline-block mr-2 editInline__text"
                >
                  <strong>Min:</strong> {{ list.minSelectedModifiers }}
                </small>
                <small
                  v-if="list.maxSelectedModifiers"
                  class="d-inline-block editInline__text"
                >
                  <strong>Max:</strong> {{ list.maxSelectedModifiers }}
                </small>
                <AtomIntegrationDisabledButton
                  :enabled="hasMenuIntegration"
                  :has-menu-integration="hasMenuIntegration"
                  class="ml-2"
                  kind="custom"
                  :label="$t('label.edit').toString()"
                >
                  <template #custom>
                    <PenSVG class="w-10 mb-1" />
                  </template>
                  <span
                    v-tooltip="{
                      autoHide: false,
                      trigger: 'click hover',
                      content: $t('label.edit'),
                    }"
                    class="editInline__icon px-2"
                  >
                    <PenSVG class="w-10 mb-1" />
                  </span>
                </AtomIntegrationDisabledButton>
              </a>
            </div>
            <div
              class="d-flex align-items-center justify-content-between minw-180 text-right"
            >
              <span v-if="list.modifiers" class="bold f-size-11">
                {{ $t("label.modifiers") }} ({{ list.modifiers.length }})
              </span>
              <div>
                <router-link
                  v-tooltip="{
                    autoHide: false,
                    trigger: 'click hover',
                    content: `${$t('label.view')}`,
                  }"
                  :to="{
                    name: 'modifier-lists.edit',
                    params: { modifierListId: list.id },
                  }"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="py-1 px-3"
                >
                  <EyeSVG class="w-14 fill_muted" />
                </router-link>
                <AtomIntegrationDisabledButton
                  :enabled="hasMenuIntegration"
                  :has-menu-integration="hasMenuIntegration"
                  class="ml-2"
                  kind="custom"
                  :label="$t('label.remove').toString()"
                >
                  <template #custom>
                    <TimesCircleSVG class="w-12 fill_muted" />
                  </template>
                  <a
                    v-tooltip="$t('label.remove')"
                    href="#"
                    class="py-1 px-3"
                    @click.prevent="destroy"
                  >
                    <TimesCircleSVG class="w-12 fill_muted" />
                  </a>
                </AtomIntegrationDisabledButton>
              </div>
            </div>
          </div>
          <OverrideModifierListSettings
            v-if="editMode"
            :business-id="businessId"
            :item-id="item.id"
            :list="list"
            @keyup.native.esc="editMode = false"
            @close="editMode = false"
          />
          <transition name="expand">
            <div v-show="showModifiers">
              <ul class="pl-0 m-0">
                <Modifier
                  v-for="modifier in list.modifiers"
                  :key="modifier.id"
                  :modifier="modifier"
                  :business-id="businessId"
                  :list-id="list.id"
                  :item-id="item.id"
                />
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Items from "@/api/items";
import Modifier from "./Modifier.vue";
import LineHeightSVG from "@/assets/svg/icons/line-height.svg";
import AngleRightSVG from "@/assets/svg/icons/angle-right.svg";
import EyeSVG from "@/assets/svg/icons/eye.svg";
import PenSVG from "@/assets/svg/icons/pen.svg";
import TimesCircleSVG from "@/assets/svg/icons/times-circle.svg";
import OverrideModifierListSettings from "./OverrideModifierListSettings.vue";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";
import { Toast } from "@/design-system";

export default {
  name: "ModifierList",
  components: {
    Modifier,
    OverrideModifierListSettings,
    AngleRightSVG,
    EyeSVG,
    LineHeightSVG,
    PenSVG,
    TimesCircleSVG,
    AtomIntegrationDisabledButton,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    hasMenuIntegration: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editMode: false,
    showModifiers: false,
  }),
  computed: {
    blockActions() {
      return (
        this.hasMenuIntegration ||
        ![
          MenuManagementSourceByIdEnum.CHATFOOD,
          MenuManagementSourceByIdEnum.OMNIVORE,
        ].includes(this.item.source.id)
      );
    },
  },
  methods: {
    goToEdit() {
      if (this.blockActions) return;
      this.editMode = true;
    },
    destroy() {
      const callback = async () => {
        await Items.detachModifierLists(this.businessId, this.item.id, [
          this.list.id,
        ]);

        this.$emit("deleted", this.list.id);
        this.$modal.hide("dialog");

        new Toast().create({
          type: "success",
          text: this.$t("message.record_unlinked"),
        });
      };
      destroyDialog(this, callback, { resourceName: this.list.name });
    },
  },
};
</script>
