<template>
  <div class="clearfix">
    <div
      class="px-4 py-2 d-flex flex-wrap align-items-center justify-content-between border-bottom minh-70"
    >
      <img
        v-if="business.logo"
        :src="imgURL"
        :alt="business.name"
        class="logoCustomer"
      />
      {{ business.name }}
    </div>
    <div class="p-4">
      <div class="row align-items-center mb-3">
        <div class="col-sm">
          <h5 class="my-2">
            {{ $t("label.edit_modifier_list") }}
          </h5>
        </div>
        <div class="col-sm-auto">
          <router-link
            :to="{ name: 'modifier-lists.create' }"
            class="btn btn-primary"
          >
            {{ $t("label.new_modifier_list") }}
          </router-link>
        </div>
      </div>
      <LoadingZone :loading="loading" :dom-visibility="false">
        <div class="border rounded p-4">
          <ViewMode v-if="viewMode" :entry="entry" @close="setEditMode" />
          <FormMode
            v-else
            :resource="entry"
            :business-id="businessId"
            @saved="recordUpdated"
            @close="setViewMode"
          />
          <hr class="my-5" />
          <Modifiers
            :business-id="businessId"
            :modifier-list="entry"
            @reordered="modifiersReordered"
          />
          <hr class="my-5" />
          <Items :business-id="businessId" :modifier-list="entry" />
        </div>
      </LoadingZone>
    </div>
  </div>
</template>

<script>
import ModifierListConstructor from "@/constructors/ModifierList";
import ModifierLists from "@/api/modifier-lists";
import ViewMode from "@/components/modifier-lists/View.vue";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import FormMode from "@/components/modifier-lists/Form.vue";
import Modifiers from "@/components/modifier-lists/modifiers/Index.vue";
import Items from "@/components/modifier-lists/items/Index.vue";
import { propOr, mergeRight } from "ramda";
import { getBusinessById } from "@/utils/mixins";
import { imageURLBuilder } from "@/v2/util/image-url-builder";

export default {
  name: "ModifierListsEdit",
  components: {
    LoadingZone,
    FormMode,
    ViewMode,
    Modifiers,
    Items,
  },
  mixins: [getBusinessById],
  props: {
    businessId: {
      type: String,
      required: true,
    },
    modifierListId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    viewMode: true,
    entry: {},
  }),
  computed: {
    modifiers() {
      return propOr([], "modifiers", this.entry);
    },
    imgURL() {
      return imageURLBuilder(this.business.logo, 100, 100);
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    setViewMode() {
      this.viewMode = true;
    },
    setEditMode() {
      this.viewMode = false;
    },
    modifiersReordered(modifiers) {
      this.entry.modifiers = modifiers;
    },
    async fetch() {
      this.loading = true;
      this.entry = await ModifierLists.find(
        this.businessId,
        this.modifierListId,
        {
          include: "items",
        }
      );
      this.loading = false;
    },
    recordUpdated(entry) {
      this.entry = new ModifierListConstructor(mergeRight(this.entry, entry));
      this.viewMode = true;
    },
  },
};
</script>
