

















































































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomButtonTypeEnum,
  OrgFormInput,
  OrgFormTextArea,
} from "@/v2/new-design-system";
import { updateMenu, IUpdateMenuResponse } from "@/v2/repo/menu/update-menu";
import { Toast } from "@/design-system";
import { AtomCheckbox } from "@/v2/design-system";

const css = bemBuilder("edit-menu-modal");

interface MenuEditResponse {
  name: string;
  notes: string;
}

export default defineComponent({
  name: "EditMenuModal",
  components: {
    AtomCheckbox,
    AtomButton,
    AtomModal,
    AtomText,
    OrgFormInput,
    OrgFormTextArea,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
    menuName: {
      type: String,
      required: true,
    },
    menuSummary: {
      type: String,
      default: "",
    },
    menuNotes: {
      type: String,
      default: "",
    },
    menuHideSnoozedItems: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onSave: {
      type: Function as PropType<(menuData: MenuEditResponse) => void>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const maxLengthMessage = 25;

    const menuData = ref<{
      name: string;
      notes: string;
      summary: string;
      hideSnoozedItems: boolean;
    }>({
      name: props.menuName,
      notes: props.menuNotes,
      summary: props.menuSummary,
      hideSnoozedItems: props.menuHideSnoozedItems,
    });

    const messageCounter = computed(() => {
      return t("module.menu.edit_menu_modal.x_characters", {
        length: menuData.value.name.length,
        max: maxLengthMessage,
      });
    });

    const errors = ref({
      name: "",
      notes: "",
      summary: "",
      hideSnoozedItems: "",
    });

    const submitCreateMenu = async () => {
      isLoading.value = true;
      let response: IUpdateMenuResponse | undefined;

      try {
        response = await updateMenu(props.businessId, props.menuId, {
          name: menuData.value.name,
          notes: menuData.value.notes,
          summary: menuData.value.summary,
          hide_snoozed_items: menuData.value.hideSnoozedItems,
        });
      } catch (e: any) {
        if (e.errors?.name || e.errors?.notes) {
          errors.value.name = e.errors?.name[0] || "";
          errors.value.notes = e.errors?.notes[0] || "";
          errors.value.summary = e.errors?.summary[0] || "";
          errors.value.hideSnoozedItems = e.errors?.hideSnoozedItems[0] || "";
          return;
        }
        new Toast().create({
          type: "error",
          title: t("module.menu.edit_menu_modal.error_title_message", {
            menuName: props.menuName,
          }),
          text: t("module.menu.edit_menu_modal.error_description_message"),
        });
      } finally {
        isLoading.value = false;
      }

      if (!response) return;
      props.onClose();
      props.onSave(response);
      new Toast().create({
        type: "success",
        title: t("module.menu.edit_menu_modal.success_title_message", {
          menuName: props.menuName,
        }),
        text: t("module.menu.edit_menu_modal.success_description_message"),
      });
    };

    return {
      t,
      css,
      menuData,
      errors,
      isLoading,
      submitCreateMenu,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      messageCounter,
      maxLengthMessage,
    };
  },
});
