<template>
  <div class="clearfix">
    <div
      class="py-3 px-4 border-bottom minh-70 d-flex flex-wrap align-items-center w-100"
    >
      <img
        v-if="business.logo"
        :src="imgURL"
        :alt="business.name"
        class="logoCustomer"
      />
      {{ business.name }}
    </div>
    <div class="p-4">
      <h5 class="mb-3">
        {{ pageTitle }}
      </h5>
      <div class="p-4 border rounded">
        <div style="max-width: 740px">
          <Form
            ref="form"
            data-test="form"
            :resource="entry"
            :business-id="businessId"
            @typeChanged="typeChanged"
            @saved="recordCreated"
            @close="close"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/items/Form";
import ItemConstructor from "@/constructors/Item";
import { getBusinessById } from "@/utils/mixins";
import { imageURLBuilder } from "@/v2/util/image-url-builder";

export default {
  name: "ItemsCreate",
  components: {
    Form,
  },
  mixins: [getBusinessById],
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    entry: new ItemConstructor({ isModifier: false }),
    pageTitleName: "Item",
  }),
  computed: {
    pageTitle() {
      return this.pageTitleName === "Item"
        ? this.$t("label.create_item")
        : this.$t("label.create_modifier");
    },
    imgURL() {
      return imageURLBuilder(this.business.logo, 100, 100);
    },
  },
  methods: {
    recordCreated({ id }) {
      this.$router.push({
        name: "items.edit",
        params: {
          businessId: this.businessId,
          itemId: id,
        },
      });
    },
    close() {
      this.$router.push({
        name: "items.index",
      });
    },
    typeChanged(val) {
      this.pageTitleName = val;
    },
  },
};
</script>
