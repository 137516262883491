<template>
  <div :class="[css(), { [css('', 'is-disabled')]: hasMenuIntegration }]">
    <input
      id="file"
      ref="file"
      type="file"
      name="photo"
      data-test-inputFile
      :class="css('input')"
      accept="image/x-png,image/jpeg"
      @change="handleFile($event)"
    />
    <div class="relative mb-2">
      <LoadingZone :loading="loading" min-height="0px">
        <a
          v-if="!imagePreview"
          href="#"
          data-test-imgPlaceholder
          class="btn btn-light p-3 text-muted photo-manager__btn"
          @click.prevent="triggerInputFile"
        >
          <UtensilsIconAlt />
          {{ t("label.choose_photo") }}
        </a>
        <a
          v-else
          href="#"
          data-test-image
          :class="css('preview')"
          :style="`background-image: url(${imagePreview})`"
          @click.prevent="triggerInputFile"
        >
          <span>{{ t("label.change_photo") }}</span>
        </a>
      </LoadingZone>
    </div>
    <AtomText :type="AtomTextTypeEnum.SMALL" :color="AtomTextColorEnum.RAINY">
      {{ t("c.items.photo_specs", { width: 550, height: 440 }) }}
    </AtomText>
    <AtomText
      :tag="AtomTextTagEnum.DIV"
      :type="AtomTextTypeEnum.SMALL"
      :color="AtomTextColorEnum.RAINY"
    >
      {{ t("c.items.photo_size", { max_size: 5 }) }}
    </AtomText>
    <AtomIntegrationDisabledButton
      v-if="hasPhoto"
      :enabled="hasMenuIntegration"
      :has-menu-integration="hasMenuIntegration"
      kind="red-label-sm"
      :label="t('label.remove_photo')"
    >
      <button
        data-test-removePhoto
        class="btn btn-link btn-sm text-danger"
        @click.prevent="removePhoto"
      >
        {{ t("label.remove_photo") }}
      </button>
    </AtomIntegrationDisabledButton>
  </div>
</template>

<script>
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import Items from "@/api/items";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import UtensilsIconAlt from "@/assets/svg/icons/utensils-alt.svg";
import { isNil, pathOr, is } from "ramda";
import { getBusinessById } from "@/utils/mixins";
import { destroyDialog } from "@/utils/helpers/dialogs";
import {
  AtomText,
  AtomTextTagEnum,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomIntegrationDisabledButton,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { t } from "@/i18n";

const css = bemBuilder("photo-manager");

export default defineComponent({
  name: "PhotoManager",
  components: {
    UtensilsIconAlt,
    LoadingZone,
    AtomText,
    AtomIntegrationDisabledButton,
  },
  mixins: [getBusinessById],
  props: {
    businessId: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      default: null,
    },
    currentPhoto: {
      type: String,
      default: null,
    },
    hasMenuIntegration: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const file = ref(null);
    const loading = ref(false);
    const imageBase64 = ref(null);

    const hasPhoto = computed(() => {
      return props.currentPhoto !== null;
    });

    const imagePreview = computed(() => {
      if (is(String, props.currentPhoto)) {
        const queryString = [
          "w=600",
          `token=${Math.floor(Math.random(4) * 100)}`,
        ].join("&");

        return `${props.currentPhoto}?${queryString}`;
      }

      if (!isNil(imageBase64.value)) {
        return imageBase64.value;
      }

      return null;
    });

    function triggerInputFile() {
      if (props.hasMenuIntegration) return;
      file.value.click();
    }

    async function removePhoto() {
      const callback = async () => {
        const currentPhoto = props.currentPhoto;
        emit("uploaded", null);

        try {
          await Items.removePhoto(props.businessId, props.itemId);
          resetImage();
        } catch (e) {
          emit("uploaded", currentPhoto);
          new Toast().create({
            type: "error",
            text: t("message.generic_failure"),
          });
        } finally {
          this.$modal.hide("dialog");
        }
      };

      destroyDialog(this, callback, {
        customMessage: t("c.items.remove_photo_confirmation"),
        confirmLabel: t("message.yes_remove_it"),
      });
    }

    async function upload() {
      if (!(file.value instanceof File) || !props.itemId) {
        return;
      }

      loading.value = true;

      try {
        const payload = new FormData();
        payload.append("photo", file.value);

        const photo = await Items.uploadPhoto(
          props.businessId,
          props.itemId,
          payload
        );

        emit("uploaded", photo);
      } catch (e) {
        const message = pathOr(
          t("message.generic_failure"),
          ["errors", "photo", "0"],
          e
        );

        new Toast().create({
          type: "error",
          text: message,
        });

        resetImage();
      } finally {
        loading.value = false;
      }
    }

    function handleFile(event) {
      file.value = pathOr(null, ["target", "files", 0], event);

      if (!file.value) return;

      if (file.value.size > 5000000) {
        file.value = null;
        new Toast().create({
          type: "error",
          text: t("c.items.error_max_file", [5]),
        });
        return;
      }

      upload();

      if (file.value instanceof File) {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          imageBase64.value = reader.result;
        });

        if (/\.(jpe?g|png|gif)$/i.test(file.value.name)) {
          reader.readAsDataURL(file.value);
        }
      }
    }

    function resetImage() {
      imageBase64.value = null;
      file.value = null;
    }

    return {
      t,
      css,
      file,
      loading,
      hasPhoto,
      imagePreview,
      handleFile,
      imageBase64,
      upload,
      triggerInputFile,
      removePhoto,
      AtomTextTagEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
</script>

<style lang="scss" scoped>
.photo-manager {
  &__input {
    display: none;
  }

  &__btn svg {
    max-height: 2.25rem;
    fill: #9bb1c1;
    margin: var(--space-7) 0;
  }

  &__preview {
    display: block;
    max-width: 12.5rem;
    width: 7.1875rem;
    height: 6.375rem;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: default;
    overflow: hidden;
    text-decoration: none;
    border-radius: 0.25rem;
    background-size: cover;
    background-position: center;
    background-color: var(--color-powder);

    > span {
      display: flex;
      padding: 1rem;
      width: 100%;
      height: 100%;
      color: var(--color-pure-white);
      text-align: center;
      align-items: center;
      justify-content: center;
      opacity: 0;
      background: rgba(0, 0, 0, 0);
      transition: all 0.2s ease-out;
    }
  }

  &:not(&--is-disabled) &__preview {
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;

      span {
        opacity: 1;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
