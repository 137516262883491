<template>
  <div class="row mt-3 mx-0 align-items-center">
    <label class="col-sm input-group mb-2 pl-0 pr-2">
      <div class="input-group-prepend">
        <span
          class="input-group-text bg-light p-2 h-35 minw-55 justify-content-center f-size-12 bold"
        >
          {{ $t("label.type") }}
        </span>
      </div>
      <select
        v-model="entry.selectionType"
        v-focus
        data-test-inputSelectionType
        class="form-control formSelect f-size-12 h-35 p-2"
        @keyup.enter="save"
      >
        <option value="single">{{ $t("label.single") }}</option>
        <option value="multiple">{{ $t("label.multiple") }}</option>
      </select>
    </label>
    <label class="col-sm input-group mb-2 pl-0 pr-2">
      <div class="input-group-prepend">
        <span
          class="input-group-text bg-light p-2 h-35 minw-55 justify-content-center f-size-12 bold"
        >
          Min
        </span>
      </div>
      <input
        v-model.number="entry.minSelectedModifiers"
        type="number"
        data-test-inputMinModifiers
        class="form-control f-size-13 h-35 p-2"
        @keyup.enter="save"
      />
    </label>
    <label class="col-sm input-group mb-2 pl-0 pr-2">
      <div class="input-group-prepend">
        <span
          class="input-group-text bg-light p-2 h-35 minw-55 justify-content-center f-size-12 bold"
        >
          Max
        </span>
      </div>
      <input
        v-model.number="entry.maxSelectedModifiers"
        type="number"
        class="form-control f-size-12 h-35 p-2"
        data-test-inputMaxModifiers
        @keyup.enter="save"
      />
    </label>
    <div class="col-auto p-0 mb-2 minw-55">
      <a
        v-tooltip="{
          autoHide: false,
          trigger: 'click hover',
          content: `${$t('label.save')}`,
        }"
        href="#"
        class="editInline__icon py-1 px-2"
        data-test-btnSave
        :class="{
          'btn-progress': loading,
        }"
        @click.prevent="save"
      >
        <AtomIcon name="check" :size="13" color="#000" />
      </a>
      <a
        v-tooltip="{
          autoHide: false,
          trigger: 'click hover',
          content: `${$t('label.cancel')}`,
        }"
        href="#"
        data-test-btnCancel
        class="editInline__icon py-1 px-2"
        @click.prevent="$emit('close')"
      >
        <AtomIcon name="times" :size="13" color="#000" />
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import Items from "@/api/items";
import { focus } from "@/utils/directives";
import { AtomIcon } from "@/v2/design-system";
import { Toast } from "@/design-system";

export default {
  name: "OverrideModifierListSettings",
  components: {
    AtomIcon,
  },
  directives: {
    focus,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    entry: null,
  }),
  created() {
    this.entry = this.list.clone();
  },
  methods: {
    async save() {
      this.loading = true;

      try {
        await Items.overrideModifierList(
          this.businessId,
          this.itemId,
          this.entry.toOverrideSettingsPayload()
        );

        this.list.minSelectedModifiers = this.entry.minSelectedModifiers;
        this.list.maxSelectedModifiers = this.entry.maxSelectedModifiers;
        this.list.selectionType = this.entry.selectionType;

        this.$emit("close");

        new Toast().create({
          type: "success",
          title: this.$t("label.success"),
          text: this.$t("message.record_updated"),
        });
      } catch (err) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
