<template>
  <LoadingZone :loading="loading" class="p-4">
    <h5 class="text-center bold mb-4">
      {{ $t("message.add_modifier_list_to") }}
      <span class="text-primary">
        {{ item.name }}
      </span>
    </h5>
    <input
      v-if="availableLists.length"
      v-model="search"
      type="text"
      class="form-control mb-3"
      :placeholder="
        $t('message.search_for_x_followed_by_ellipses', {
          search: $t('label.modifier_lists'),
        })
      "
    />
    <ul class="p-0 m-0" style="max-height: calc(100vh - 320px); overflow: auto">
      <li
        v-for="(list, index) in filteredLists"
        :key="list.id"
        class="customCheckbox border-bottom"
      >
        <input
          :id="index"
          v-model="addLists"
          :value="list"
          type="checkbox"
          class="customCheckbox__input"
        />
        <label :for="index" class="customCheckbox__text pt-3 pb-2">
          <span class="customCheckbox__square"></span>
          <div class="row m-0">
            <div class="col">
              <span :title="createdAtLabel(list.createdAt)">
                {{ list.name }}
              </span>
            </div>
            <div class="col-auto">
              <small class="bold">
                {{ list.selectionType | cfStringBeautify }}
              </small>
            </div>
          </div>
          <div class="row m-0">
            <div class="col">
              <div v-if="list.modifiers.length" class="d-flex flex-wrap">
                <small
                  v-for="(modifier, key) in list.modifiers"
                  :key="key"
                  class="badge badge-light mr-1 mb-1 f-size-10 p-2 custom-badge"
                >
                  {{ modifier.name }}
                </small>
              </div>
              <small v-else class="text-muted">
                {{ $t("message.no_modifiers_associated") }}
              </small>
            </div>
            <div class="col-auto text-center">
              <small
                v-if="list.minSelectedModifiers || list.maxSelectedModifiers"
                class="clearfix text-center"
              >
                <div v-if="list.minSelectedModifiers">
                  {{ $t("label.minimum") }}:
                  <strong>{{ list.minSelectedModifiers }}</strong>
                </div>
                <div v-if="list.maxSelectedModifiers">
                  {{ $t("label.maximum") }}:
                  <strong>{{ list.maxSelectedModifiers }}</strong>
                </div>
              </small>
            </div>
          </div>
        </label>
      </li>
    </ul>
    <div v-if="availableLists.length && !filteredLists.length" class="my-5">
      <p class="text-center">
        {{ $t("message.there_is_no_modifier_list_matching") }}
      </p>
    </div>
    <div v-if="!availableLists.length" class="mt-5 mb-4">
      <p class="text-center">
        {{ $t("message.there_is_no_modifier_list_attached") }}
      </p>
      <p class="text-center">
        <router-link
          class="btn btn-dark"
          :to="{ name: 'modifier-lists.create' }"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("label.create_modifier_list") }}
        </router-link>
      </p>
    </div>
    <div class="clearfix mt-4">
      <button class="btn btn-light" @click="$emit('close')">
        {{ $t("label.cancel") }}
      </button>
      <button
        class="float-right btn btn-primary"
        :class="{ 'btn-progress': adding }"
        :disabled="!addLists.length"
        @click="save"
      >
        {{ $tc("label.add_x_lists", addLists.length) }}
      </button>
    </div>
  </LoadingZone>
</template>

<script>
import Items from "@/api/items";
import ModifierLists from "@/api/modifier-lists";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import { pluck, isEmpty, is, sortWith, ascend, prop } from "ramda";
import { date } from "@/utils/filters";
import { Toast } from "@/design-system";

export default {
  name: "ModifierListsList",
  components: {
    LoadingZone,
  },
  props: {
    listsAdded: {
      type: Function,
      default: () => {},
    },
    excludeLists: {
      type: Array,
      default: null,
    },
    businessId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    search: "",
    adding: false,
    loading: false,
    lists: [],
    addLists: [],
  }),
  computed: {
    availableLists() {
      if (!is(Array, this.excludeLists)) return this.lists;

      return this.lists.filter((obj) => !this.excludeLists.includes(obj.id));
    },
    filteredLists() {
      if (isEmpty(this.search)) return this.availableLists;

      const query = this.search.trim().toLowerCase();
      return this.availableLists.filter((obj) =>
        obj.name.toLowerCase().includes(query)
      );
    },
  },
  created() {
    this.fetch();
  },
  mounted() {},
  methods: {
    createdAtLabel(createdAt) {
      if (!createdAt) return;

      return `${this.$t("label.created_at")}: ${date(
        createdAt,
        "MMM DD, YYYY - h:mm A"
      )}`;
    },
    getModifierNames(modifiers) {
      return pluck("name", modifiers).join(", ");
    },
    async fetch() {
      this.loading = true;

      const res = await ModifierLists.get(this.businessId, {
        include: "items",
        limit: 9999,
        source: this.item.source.id,
      });

      this.lists = sortWith([ascend(prop("name"))], res.data);

      this.loading = false;
    },
    async save() {
      this.adding = true;

      try {
        await Items.attachModifierLists(
          this.businessId,
          this.item.id,
          pluck("id", this.addLists)
        );

        this.listsAdded(this.addLists);
        this.$emit("close");

        new Toast().create({
          type: "success",
          text: this.$t("message.records_linked"),
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.adding = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-badge {
  text-align: left;
  line-height: 130%;
  white-space: normal;
}
</style>
