<template>
  <LoadingZone :loading="loading" class="p-4">
    <h5 class="text-center bold mb-4">
      {{ $t("c.items.printing_groups_list.items_by_group.attached_items") }}
      <span class="text-primary">
        {{ groupName }}
      </span>
    </h5>
    <input
      v-model="search"
      type="text"
      class="form-control mb-3"
      :placeholder="
        $t('message.search_for_x_followed_by_ellipses', {
          search: $t('c.items.printing_groups_list.search_printing_groups'),
        })
      "
    />
    <ul class="p-0 m-0" style="max-height: calc(100vh - 320px); overflow: auto">
      <li
        v-for="item in filteredLists"
        :key="item.id"
        class="customCheckbox border-bottom pl-0 pt-3 pb-2"
      >
        <div class="row m-0">
          <div class="col pl-0 d-flex flex-row justify-content-between">
            <span class="d-flex">
              {{ item.name }}
            </span>
            <span>
              <AtomMoney
                :amount="item.price.value"
                :currency="item.price.currency"
              />
            </span>
          </div>
        </div>
      </li>
    </ul>
    <div v-if="!filteredLists.length && items.length" class="my-5">
      <p class="text-center">
        {{
          $t(
            "c.items.printing_groups_list.items_by_group.there_is_no_items_matching"
          )
        }}
      </p>
    </div>
    <div v-if="!items.length" class="mt-5 mb-4">
      <p class="text-center">
        {{
          $t(
            "c.items.printing_groups_list.items_by_group.there_is_no_items_attached"
          )
        }}
      </p>
    </div>
    <div class="clearfix mt-4">
      <button class="btn btn-light" @click="$emit('close')">
        {{ $t("label.cancel") }}
      </button>
    </div>
  </LoadingZone>
</template>

<script>
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import { Toast } from "@/design-system";
import { isEmpty } from "ramda";
import { fetchPrintingGroupItem } from "@/v2/repo/fetch-printing-group-item";
import { AtomMoney } from "@/v2/new-design-system";

export default {
  name: "PrintingGroupItemList",
  components: {
    LoadingZone,
    AtomMoney,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    itemsIds: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: "",
    loading: false,
    items: [],
  }),
  computed: {
    filteredLists() {
      if (isEmpty(this.search)) return this.items;

      const query = this.search.trim().toLowerCase();
      return this.items.filter((obj) => obj.name.toLowerCase().includes(query));
    },
  },
  created() {
    this.fetchItemsByGroup();
  },
  methods: {
    async fetchItemsByGroup() {
      this.loading = true;

      try {
        this.items = await fetchPrintingGroupItem({
          businessId: this.businessId,
          itemIds: this.itemsIds,
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
