<template>
  <form data-test="form" style="max-width: 740px" @submit.prevent="submitForm">
    <div class="row">
      <div class="col-12 col-md">
        <div class="form-group">
          <label for="name">{{ $t("label.name") }}</label>
          <input
            id="name"
            v-model="entry.name"
            data-test="inputName"
            type="text"
            class="form-control"
          />
          <span v-if="entry.hasError('name')" class="error">
            {{ entry.firstError("name") }}
          </span>
        </div>
        <div class="form-group">
          <label for="sku"> {{ $t("label.sku") }} </label>
          <span class="optional-label">{{ $t("label.optional") }}</span>
          <input
            id="sku"
            v-model.trim="entry.sku"
            maxlength="50"
            data-test="inputName"
            type="text"
            class="form-control"
            :disabled="!fullEdit"
          />
          <span v-if="entry.hasError('sku')" class="error">
            {{ entry.firstError("sku") }}
          </span>
        </div>
        <div class="row">
          <div class="col-auto">
            <div class="form-group">
              <label class="d-block mt-1">{{ $t("label.type") }}</label>
              <template v-if="isCreating">
                <RadioButton
                  ref="setAsItem"
                  v-model="entry.isModifier"
                  class="mr-2"
                  name="isModifier"
                  :label="$t('label.item')"
                  :checked-value="false"
                  @input="typeChanged('Item')"
                />
                <RadioButton
                  ref="setAsModifier"
                  v-model="entry.isModifier"
                  name="isModifier"
                  :label="$t('label.modifier')"
                  :checked-value="true"
                  @input="typeChanged('Modifier')"
                />
              </template>
              <span
                v-else
                data-test="isModifierView"
                class="btn btn-block btn-outline-dark btn-outline--custom item-form-label"
              >
                {{ $t(entry.isModifier ? "label.modifier" : "label.item") }}
              </span>
              <span v-if="entry.hasError('isModifier')" class="error">
                {{ entry.firstError("isModifier") }}
              </span>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="amount">{{ $t("label.price") }}</label>
              <InputMoney
                id="amount"
                v-model="entry.amount"
                data-test="InputMoney"
                :business-id="businessId"
                :disabled="!fullEdit"
              />
              <span v-if="entry.hasError('amount')" class="error">
                {{ entry.firstError("amount") }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="!entry.isModifier" class="form-group">
          <label for="description">{{ $t("label.description") }}</label>
          <span class="optional-label">{{ $t("label.optional") }}</span>
          <textarea
            id="description"
            v-model="entry.description"
            data-test="inputDescription"
            class="form-control"
            name="description"
            type="text"
            rows="4"
          />
          <span v-if="entry.hasError('description')" class="error">
            {{ entry.firstError("description") }}
          </span>
        </div>
      </div>
      <div v-if="showPhotoManager" class="col-auto">
        <div class="form-group">
          <label for="file">{{ $t("label.photo") }}</label>
          <PhotoManager
            ref="photoManager"
            :business-id="businessId"
            :item-id="entry.id"
            :current-photo="entry.photo"
            @uploaded="setPhoto"
          />
        </div>
      </div>
    </div>
    <div class="clearfix">
      <button
        type="submit"
        data-test="btnSubmit"
        class="btn btn-primary"
        :class="{ 'btn-progress': loading }"
      >
        {{ $t("label.save") }}
      </button>
      <a
        href="#"
        class="btn btn-light"
        data-test="btnBack"
        @click.prevent="$emit('close')"
      >
        {{ $t("label.cancel") }}
      </a>
    </div>
  </form>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import Items from "@/api/items";
import ItemConstructor from "@/constructors/Item";
import RadioButton from "@/components/helpers/forms/RadionButton.vue";
import PhotoManager from "./PhotoManager.vue";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";
import { InputMoney, Toast } from "@/design-system";

export default {
  name: "Form",
  components: {
    InputMoney,
    RadioButton,
    PhotoManager,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
    isCreating: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    entry: {},
    loading: false,
  }),
  computed: {
    showPhotoManager() {
      return !this.entry.isModifier;
    },
    successMessage() {
      return this.isCreating
        ? this.$t("message.record_created")
        : this.$t("message.record_updated");
    },
    fullEdit() {
      if (this.isCreating) return true;

      return (
        this.resource?.source?.isEditable &&
        this.resource?.source?.id === MenuManagementSourceByIdEnum.CHATFOOD
      );
    },
  },
  watch: {
    resource: {
      handler(val) {
        if (!this.createMode) {
          this.entry = val.clone();
        } else {
          this.entry = new ItemConstructor({ isModifier: false });
        }
      },
      immediate: true,
    },
  },
  methods: {
    setPhoto(photoLink) {
      this.entry.photo = photoLink;
      this.resource.photo = photoLink;
    },
    setAsItem() {
      this.entry.isModifier = false;
    },
    setAsModifier() {
      this.entry.isModifier = true;
    },
    async request() {
      return this.isCreating
        ? Items.create(this.businessId, this.entry.toCreatePayload())
        : Items.update(
            this.businessId,
            this.entry.id,
            this.entry.toUpdatePayload()
          );
    },
    async submitForm() {
      this.loading = true;

      try {
        const res = await this.request();
        await (this.entry = res);

        if (this.isCreating && this.$refs.photoManager) {
          await this.$refs.photoManager.upload();
        }

        this.$emit("saved", this.entry);

        new Toast().create({
          type: "success",
          text: this.successMessage,
        });
      } catch (e) {
        this.entry.errors = e.errors;

        this.loading = false;
      }
    },
    typeChanged(obj) {
      this.$emit("typeChanged", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-form-label {
  pointer-events: none;
}
</style>
