<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col">
        <h5 class="m-0">
          {{ $t("label.modifier_lists") }}
          <small v-if="lists.length" class="text-muted bold ml-1">
            ({{ lists.length }})
          </small>
        </h5>
      </div>
      <div class="col-auto">
        <AtomIntegrationDisabledButton
          :enabled="hasMenuIntegration"
          :has-menu-integration="hasMenuIntegration"
          kind="blue-label"
          :label="$t('label.add_modifier_list').toString()"
        >
          <a
            href="#"
            class="d-inline-block text-primary text-decoration-none bold py-2 f-size-13"
            @click.prevent="showAddLists"
          >
            {{ $t("label.add_modifier_list") }}
          </a>
        </AtomIntegrationDisabledButton>
      </div>
    </div>
    <div
      v-if="lists.length"
      class="d-grid overflow-sm"
      data-test="listContainer"
    >
      <Draggable
        v-model="modifierLists"
        v-bind="dragOptions"
        @input="updateListsOrder"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <ModifierList
            v-for="(list, index) in lists"
            :key="list.id"
            :index="index"
            :has-menu-integration="hasMenuIntegration"
            :list="list"
            :business-id="businessId"
            :item="item"
            @deleted="listDeleted"
          />
        </transition-group>
      </Draggable>
    </div>
    <div v-else class="my-5">
      <p class="text-center">
        {{ $t("message.there_is_no_modifier_list_attached") }}
      </p>
      <p v-if="!hasMenuIntegration" class="text-center">
        <a href="#" class="btn btn-dark" @click.prevent="showAddLists">
          {{ $t("label.add_modifier_list") }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import ModifierListsList from "./ModifierListsList.vue";
import ModifierList from "./ModifierList.vue";
import Items from "@/api/items";
import Draggable from "vuedraggable";
import { pluck, indexOf, find } from "ramda";
import { debounce } from "@/utils/helpers/functions";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "ModifierLists",
  components: {
    ModifierList,
    Draggable,
    AtomIntegrationDisabledButton,
  },
  props: {
    lists: {
      type: Array,
      default: () => [],
    },
    businessId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    drag: false,
  }),
  computed: {
    hasMenuIntegration() {
      return ![
        MenuManagementSourceByIdEnum.CHATFOOD,
        MenuManagementSourceByIdEnum.OMNIVORE,
      ].includes(this.item.source.id);
    },
    modifierLists: {
      get() {
        return this.lists;
      },
      set(val) {
        this.$emit("reordered", val);
      },
    },
    dragOptions() {
      return {
        animation: 300,
        group: "modifier_lists",
        disabled: this.hasMenuIntegration,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    updateListsOrder: debounce(async function (lists) {
      await Items.sortModifierLists(
        this.businessId,
        this.item.id,
        pluck("id", lists)
      );
    }, 1500),
    showAddLists() {
      this.$modal.show(
        ModifierListsList,
        {
          listsAdded: (lists) => {
            this.lists.push(...lists);
          },
          excludeLists: pluck("id", this.lists),
          businessId: this.businessId,
          item: this.item,
        },
        {
          name: "modifier_lists_list",
          adaptive: true,
          resizable: true,
          height: "auto",
          clickToClose: false,
        }
      );
    },
    listDeleted(listId) {
      const index = indexOf(
        find((obj) => obj.id === listId, this.lists),
        this.lists
      );
      this.lists.splice(index, 1);
    },
  },
};
</script>
