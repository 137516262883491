<template>
  <a
    href="#"
    class="item-list"
    data-test="select-item"
    @click.prevent="$emit('openItem', item.id)"
  >
    <div class="item-list__wrapper">
      <div class="row">
        <div class="col">
          <h6 class="f-w-600 text-dark">{{ item.name }}</h6>
          <small
            v-if="item.description"
            class="truncate__lines--3 text-greyLight"
          >
            {{ item.description }}
          </small>
          <Money
            class="mt-2 m-0 f-size-12 f-w-600 text-dark"
            tag="p"
            :amount="item.amount"
            :business-id="item.businessId"
          />
        </div>
        <div v-if="item.photo" class="col-auto pl-0">
          <img
            :src="`${item.photo}?fm=jpeg&h=300&w=300&q=90&fit=crop&crop=center`"
            class="img-fluid rounded maxw-90"
          />
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import Money from "@/components/helpers/Money.vue";

export default {
  name: "Item",
  components: {
    Money,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  display: block;
  text-decoration: none;
  padding: 0 1rem;

  &__wrapper {
    padding: 1.25rem 0;
    border-bottom: solid 1px #eee;
  }

  &:last-child .item-list__wrapper {
    border-bottom: 0;
  }
}
</style>
