<template>
  <form data-test="form" style="max-width: 740px" @submit.prevent="submitForm">
    <div class="form-group">
      <label for="name">{{ $t("label.name") }}</label>
      <input
        id="name"
        v-model="entry.name"
        class="form-control"
        data-test="inputName"
        type="text"
      />
      <span v-if="entry.hasError('name')" class="error">
        {{ entry.firstError("name") }}
      </span>
    </div>
    <div class="form-group">
      <label for="displayName">{{ $t("label.display_name") }}</label>
      <input
        id="displayName"
        v-model="entry.displayName"
        class="form-control"
        type="text"
        data-test="inputDisplayName"
      />
      <span v-if="entry.hasError('displayName')" class="error">
        {{ entry.firstError("displayName") }}
      </span>
    </div>
    <div class="row">
      <div class="col-sm">
        <div class="form-group">
          <label for="minSelectedModifiers">
            {{ $t("label.min_modifiers") }}
          </label>
          <input
            id="minSelectedModifiers"
            v-model.number="entry.minSelectedModifiers"
            class="form-control"
            data-test="inputMin"
            type="number"
            @input="validateAmount"
          />
          <span v-if="entry.hasError('minSelectedModifiers')" class="error">
            {{ entry.firstError("minSelectedModifiers") }}
          </span>
        </div>
      </div>
      <div class="col-sm">
        <div class="form-group">
          <label for="maxSelectedModifiers">
            {{ $t("label.max_modifiers") }}
          </label>
          <input
            id="maxSelectedModifiers"
            v-model.number="entry.maxSelectedModifiers"
            class="form-control"
            data-test="inputMax"
            type="number"
            @input="validateAmount"
          />
          <span v-if="entry.hasError('maxSelectedModifiers')" class="error">
            {{ entry.firstError("maxSelectedModifiers") }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="d-inline-block customCheckbox mb-4"
      :class="{ 'text-muted': isSelectionTypeDisabled }"
    >
      <input
        id="type"
        v-model="entry.selectionType"
        type="checkbox"
        class="customCheckbox__input"
        data-test="inputSelectionType"
        :disabled="isSelectionTypeDisabled"
        true-value="multiple"
        false-value="single"
      />
      <label for="type" class="customCheckbox__text">
        <span class="customCheckbox__square"></span>
        {{ $t("message.allow_multiple_selection") }}
      </label>
      <span v-if="entry.hasError('selectionType')" class="error">
        {{ entry.firstError("selectionType") }}
      </span>
    </div>
    <div class="clearfix">
      <button
        type="submit"
        data-test="btnSubmit"
        class="btn btn-primary"
        :class="{ 'btn-progress': loading }"
      >
        {{ $t("label.save") }}
      </button>
      <a
        href="#"
        class="btn btn-light"
        data-test="btnBack"
        @click.prevent="$emit('close')"
      >
        {{ $t("label.cancel") }}
      </a>
    </div>
  </form>
</template>

<script>
import ModifierLists from "@/api/modifier-lists";
import ModifierListConstructor from "@/constructors/ModifierList";
import { isNil, propOr, gt } from "ramda";
import { isNilOrEmpty } from "ramda-extension";
import { Toast } from "@/design-system";

export default {
  name: "Form",
  props: {
    businessId: {
      type: String,
      required: true,
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    entry: new ModifierListConstructor({ selectionType: "single" }),
  }),
  computed: {
    isCreating() {
      return isNil(this.resource);
    },
    successMessage() {
      return this.isCreating
        ? this.$t("message.record_created")
        : this.$t("message.record_updated");
    },
    hasMaxSelection() {
      return !isNilOrEmpty(this.entry.maxSelectedModifiers);
    },
    isMinGtMaxSelection() {
      const min = propOr(0, "minSelectedModifiers", this.entry);
      const max = propOr(0, "maxSelectedModifiers", this.entry);

      return gt(min, max);
    },
    isSelectionTypeDisabled() {
      const max = propOr(0, "maxSelectedModifiers", this.entry);
      const isDisabled = gt(2, max);

      return isDisabled;
    },
  },
  created() {
    this.setEntry();
  },
  methods: {
    validateAmount() {
      const method =
        this.isMinGtMaxSelection && this.hasMaxSelection
          ? "setError"
          : "removeError";

      this.entry[method](
        "maxSelectedModifiers",
        "Min can't be greater than max value."
      );

      if (this.hasMaxSelection && this.isSelectionTypeDisabled) {
        this.entry.selectionType = "single";
      }
    },
    setEntry() {
      if (!this.isCreating) {
        this.entry = this.resource.clone();
      }
    },
    async request() {
      if (this.isCreating) {
        return ModifierLists.create(
          this.businessId,
          this.entry.toCreatePayload()
        );
      }

      return ModifierLists.update(
        this.businessId,
        this.entry.id,
        this.entry.toUpdatePayload()
      );
    },
    async submitForm() {
      this.loading = true;

      try {
        const res = await this.request();
        this.entry = res;

        this.$emit("saved", this.entry);

        new Toast().create({
          type: "success",
          text: this.successMessage,
        });
      } catch (e) {
        this.entry.errors = e.errors;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
