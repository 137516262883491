<template>
  <div style="max-width: 740px">
    <div class="row">
      <div class="col">
        <div class="f-size-12 f-w-500 text-muted">{{ $t("label.name") }}:</div>
        <p class="mb-4">
          {{ entry.name }}
        </p>
        <div class="row">
          <div class="col-md">
            <div class="f-size-12 f-w-500 text-muted">
              {{ $t("label.price") }}:
            </div>
            <Money
              tag="p"
              data-test="price"
              class="mb-4"
              :amount="entry.amount"
              :business-id="businessId"
            />
          </div>
          <div class="col-md">
            <div class="f-size-12 f-w-500 text-muted">
              {{ $t("label.type") }}:
            </div>
            <p data-test="type" class="mb-4">
              {{ $t(entry.isModifier ? "label.modifier" : "label.item") }}
            </p>
          </div>
        </div>
        <div class="row">
          <div v-if="entry.sku" class="col-md mb-4">
            <div class="f-size-12 f-w-500 text-muted">
              {{ $t("label.sku") }}:
            </div>
            {{ entry.sku }}
          </div>
          <div v-if="entry.source" class="col-md mb-4">
            <div class="f-size-12 f-w-500 text-muted">
              {{ $t("label.source") }}:
            </div>
            {{ entry.source.name }}
          </div>
        </div>
        <div v-if="entry.description" data-test="description">
          <div class="f-size-12 f-w-500 text-muted">
            {{ $t("label.description") }}:
          </div>
          <p class="mb-4">{{ entry.description }}</p>
        </div>
      </div>
      <div v-if="showPhotoManager" class="col-auto">
        <div class="f-size-12 f-w-500 text-muted">{{ $t("label.photo") }}:</div>
        <PhotoManager
          :business-id="businessId"
          :item-id="entry.id"
          :current-photo="entry.photo"
          :has-menu-integration="!canEdit"
          @uploaded="setPhoto"
        />
      </div>
    </div>
    <div class="clearfix">
      <AtomIntegrationDisabledButton
        :enabled="!canEdit"
        :has-menu-integration="!canEdit"
        kind="default"
        class="mr-2"
        :label="$t('label.edit').toString()"
      >
        <a
          href="#"
          data-test="edit"
          class="btn btn-primary"
          @click.prevent="$emit('close')"
        >
          {{ $t("label.edit") }}
        </a>
      </AtomIntegrationDisabledButton>
      <a
        href="#"
        data-test="back"
        class="btn btn-light"
        @click.prevent="goBack()"
      >
        {{ $t("label.back") }}
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import PhotoManager from "./PhotoManager.vue";
import Money from "@/components/helpers/Money.vue";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "ViewRecord",
  components: {
    PhotoManager,
    Money,
    AtomIntegrationDisabledButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    compressedPhoto() {
      if (!this.entry.photo) return null;

      return `${this.entry.photo}?w=300`;
    },
    showPhotoManager() {
      return !this.entry.isModifier;
    },
    canEdit() {
      return [
        MenuManagementSourceByIdEnum.CHATFOOD,
        MenuManagementSourceByIdEnum.OMNIVORE,
        MenuManagementSourceByIdEnum.TEVALIS,
      ].includes(this.entry.source.id);
    },
  },
  methods: {
    setPhoto(photoLink) {
      this.entry.photo = photoLink;
    },
    goBack() {
      if (window.history.length > 2) {
        this.$router.go(-1);
        return;
      }

      this.$router.push({
        name: "items.index",
      });
    },
  },
};
</script>
