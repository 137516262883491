<template>
  <div
    class="d-flex w-100 align-items-center justify-content-between py-2 minh-55 border-top"
  >
    <span class="minw-200 w-100">{{ item.name }}</span>
    <router-link
      v-tooltip="{
        autoHide: false,
        trigger: 'click hover',
        content: `${$t('label.view')}`,
      }"
      :to="{
        name: 'items.edit',
        params: { itemId: item.id },
      }"
      target="_blank"
      rel="noopener noreferrer"
      class="py-1 px-3"
    >
      <EyeSVG class="w-14 fill_muted" />
    </router-link>
    <AtomIntegrationDisabledButton
      :enabled="hasMenuIntegration"
      :has-menu-integration="hasMenuIntegration"
      class="ml-2"
      kind="custom"
      :label="$t('label.remove').toString()"
    >
      <template #custom>
        <TimesCircleSVG class="w-12 fill_muted" />
      </template>
      <a
        v-tooltip="$t('label.remove')"
        href="#"
        data-test="destroy"
        class="py-1 px-3"
        @click.prevent="destroy"
      >
        <TimesCircleSVG class="w-12 fill_muted" />
      </a>
    </AtomIntegrationDisabledButton>
  </div>
</template>

<script>
import Items from "@/api/items";
import EyeSVG from "@/assets/svg/icons/eye.svg";
import TimesCircleSVG from "@/assets/svg/icons/times-circle.svg";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { Toast } from "@/design-system";

export default {
  name: "Item",
  components: {
    EyeSVG,
    TimesCircleSVG,
    AtomIntegrationDisabledButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    modifierListId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    hasMenuIntegration: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    destroy() {
      const callback = async () => {
        try {
          await Items.detachModifierLists(this.businessId, this.item.id, [
            this.modifierListId,
          ]);
          this.$emit("deleted", this.item.id);

          new Toast().create({
            type: "success",
            text: this.$t("message.record_unlinked"),
          });
        } catch (e) {
          new Toast().create({
            type: "error",
            text: this.$t("message.generic_failure"),
          });
        } finally {
          this.$modal.hide("dialog");
        }
      };
      destroyDialog(this, callback, { resourceName: this.item.name });
    },
  },
};
</script>
