<template>
  <div>
    <input
      :id="modifierItem"
      type="checkbox"
      class="modifier-item__input-hide"
    />
    <label :for="modifierItem" class="modifier-item__label">
      <div class="row">
        <div class="col-auto">
          <span class="webview-selector--checkbox"></span>
        </div>
        <div class="col pl-0">
          {{ modifier.name }}
          <Money
            v-if="modifier.amount > 0"
            class="text-greyLight f-size-11"
            :amount="modifier.amount"
            :business-id="modifier.businessId"
          />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import Money from "@/components/helpers/Money.vue";

export default {
  name: "CheckboxSelector",
  components: { Money },
  props: {
    modifierListId: {
      type: String,
      required: true,
    },
    modifier: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modifierItem() {
      return this.modifierListId + "-" + this.modifier.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.webview-selector--checkbox {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 3px;
  border: solid 1px var(--color-brand-primary);
  background-color: var(--color-white);
  transition: all 0.2s ease-out;
}

.modifier-item__input-hide:checked
  + .modifier-item__label
  .webview-selector--checkbox {
  border-color: var(--color-brand-primary);
  background-color: var(--color-brand-primary);
  box-shadow: 0 4px 6px rgba(96, 108, 220, 0.3);

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    border-radius: 20px;
    background: var(--color-white);
  }

  &:before {
    width: 12px;
    transform: rotate(-45deg) translate(-2px, 10px);
  }

  &:after {
    width: 8px;
    transform: rotate(45deg) translate(9px, 5px);
  }
}
</style>
