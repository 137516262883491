import { http } from "../http";
import { IRepoErrors } from "../errors";
import {
  IDetachPrintingGroupsRequest,
  IDetachPrintingGroupsBodyParams,
} from "./types";

export class DetachPrintingGroupsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: DetachPrintingGroupsError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function detachPrintingGroups(
  request: IDetachPrintingGroupsRequest
): Promise<void> {
  try {
    const params: IDetachPrintingGroupsBodyParams = {
      groups: request.groupIds,
      item_id: request.itemId,
    };

    await http.post(
      `/printing/business/${request.businessId}/detach-groups-from-item`,
      params
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new DetachPrintingGroupsError(error);
  }
}
