import { AxiosResponse } from "axios";
import { http } from "../../http";
import { IMenu, IAxiosErrorResponse } from "../types";
import { mapMenuValues } from "../util";

export interface IUpdateMenuResponse extends IMenu {}

export async function updateMenu(
  businessId: string,
  menuId: string,
  payload: {
    name: string;
    notes: string;
    summary: string;
    hide_snoozed_items: boolean;
  }
): Promise<IMenu> {
  let response: AxiosResponse;

  try {
    response = await http.put(
      `/api/v3/businesses/${businessId}/menus/${menuId}`,
      payload
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message: rawErrorMessage || "The request to create a menu has failed.",
      response: response.data,
    };
    throw error.response;
  }

  return mapMenuValues(response?.data || {});
}
