
































import { t } from "@/i18n";
import { defineComponent, ref } from "@vue/composition-api";
import { MolUploadImage, MolFormControl } from "@/v2/new-design-system";
import { OrgImageCropper } from "@/v2/design-system";
import { bemBuilder } from "@chatfood/core-utils";
import { report } from "@chatfood/bug-reporter";
import { uploadMenuImage } from "@/v2/repo/menu/upload-menu-image";
import { uploadMenuIcon } from "@/v2/repo/menu/upload-menu-icon";
import { imageURLBuilder } from "@/v2/util/image-url-builder";

const css = bemBuilder("edit-images");

export default defineComponent({
  name: "EditImages",
  components: {
    MolFormControl,
    OrgImageCropper,
    MolUploadImage,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const imageRef = ref<string>(props.image);
    const iconRef = ref<string>(props.icon);
    const imageToCrop = ref<string>();
    const croppingResource = ref<"image" | "icon">();

    const onImageSelected = async (
      imageFile: File,
      resource: "image" | "icon"
    ) => {
      imageToCrop.value = URL.createObjectURL(imageFile);
      croppingResource.value = resource;
    };

    const onCancelCrop = () => {
      imageToCrop.value = undefined;
      croppingResource.value = undefined;
    };

    const imageCropperConfig = ref<{
      width: number;
      height: number;
      ratio: number;
    }>({
      width: 200,
      height: 200,
      ratio: 16 / 9,
    });

    const isImageSaving = ref(false);
    const onImageCropped = (file: File & { lastModifiedDate: Date }) => {
      croppingResource.value === "image"
        ? onUploadImage(file)
        : onUploadIcon(file);
    };

    const onUploadImage = async (file: File & { lastModifiedDate: Date }) => {
      isImageSaving.value = true;

      try {
        onCancelCrop();
        file.lastModifiedDate = new Date();
        const res = await uploadMenuImage(props.businessId, props.menuId, file);
        imageRef.value = imageURLBuilder(res, 300, 300);
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isImageSaving.value = false;
      }
    };

    const isIconSaving = ref(false);
    const onUploadIcon = async (file: File & { lastModifiedDate: Date }) => {
      isIconSaving.value = true;

      try {
        onCancelCrop();
        file.lastModifiedDate = new Date();

        const res = await uploadMenuIcon(props.businessId, props.menuId, file);
        iconRef.value = imageURLBuilder(res, 300, 300);
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isIconSaving.value = false;
      }
    };

    return {
      t,
      css,
      imageRef,
      iconRef,
      isImageSaving,
      isIconSaving,
      onImageSelected,
      onCancelCrop,
      imageToCrop,
      imageCropperConfig,
      onImageCropped,
      onUploadImage,
      onUploadIcon,
    };
  },
});
