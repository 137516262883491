import { http } from "../http";
import { IRepoErrors } from "../errors";
import {
  IAttachPrintingGroupsRequest,
  IAttachPrintingGroupsBodyParams,
} from "./types";

export class AttachPrintingGroupsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: AttachPrintingGroupsError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function attachPrintingGroups(
  request: IAttachPrintingGroupsRequest
): Promise<void> {
  try {
    const params: IAttachPrintingGroupsBodyParams = {
      groups: request.groupIds,
      item_id: request.itemId,
    };

    await http.post(
      `/printing/business/${request.businessId}/attach-groups-to-item`,
      params
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new AttachPrintingGroupsError(error);
  }
}
