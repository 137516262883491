var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center editInline relative"},[_c('InputMoney',{attrs:{"data-test":"inputAmount","on-focus":true,"business-id":_vm.businessId},nativeOn:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('close')}]},model:{value:(_vm.modifier.newAmount),callback:function ($$v) {_vm.$set(_vm.modifier, "newAmount", $$v)},expression:"modifier.newAmount"}}),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      autoHide: false,
      trigger: 'click hover',
      content: _vm.$t('label.save'),
    }),expression:"{\n      autoHide: false,\n      trigger: 'click hover',\n      content: $t('label.save'),\n    }"}],staticClass:"editInline__icon py-1 px-2",class:{
      'btn-progress': _vm.loading,
    },attrs:{"href":"#","data-test":"btnSave"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('CheckSVG',{staticClass:"w-13"})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      autoHide: false,
      trigger: 'click hover',
      content: _vm.$t('label.cancel'),
    }),expression:"{\n      autoHide: false,\n      trigger: 'click hover',\n      content: $t('label.cancel'),\n    }"}],staticClass:"editInline__icon py-1 px-2",attrs:{"href":"#","data-test":"btnCancel"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_c('TimesSVG',{staticClass:"w-10"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }