<template>
  <div class="pt-4">
    <div class="bg-white box-shadow-1">
      <h4 class="pt-4 px-3 m-0 f-w-500 f-size-20">{{ category.name }}</h4>
      <div v-if="category.items.length" data-test="item">
        <Item
          v-for="(item, index) in category.items"
          :key="index"
          :item="item"
          @openItem="openItem"
        />
      </div>
      <p v-else class="text-center py-4 px-5 m-0" data-test="item-empty">
        {{ $t("message.add_items_to_category") }}
      </p>
    </div>
  </div>
</template>

<script>
import Item from "./Item.vue";

export default {
  name: "Category",
  components: {
    Item,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openItem(itemId) {
      this.$emit("openItem", itemId);
    },
  },
};
</script>
