<template>
  <div class="clearfix">
    <div
      class="d-flex flex-wrap align-items-center justify-content-between w-100 py-2 px-4 border-bottom minh-70"
    >
      <div class="my-2">
        <img
          v-if="business.logo"
          :src="imgURL"
          :alt="business.name"
          class="logoCustomer"
        />
        {{ business.name }}
      </div>
    </div>
    <div class="p-4">
      <div class="row align-items-center mb-3">
        <div class="col">
          <h5 class="my-2">{{ pageTitle }}</h5>
        </div>
        <div class="col-auto">
          <router-link :to="{ name: 'items.create' }" class="btn btn-primary">
            {{ $t("label.new_item") }}
          </router-link>
        </div>
      </div>
      <LoadingZone :loading="loading" :dom-visibility="false">
        <div class="p-4 border rounded">
          <ViewMode
            v-if="viewMode"
            :entry="entry"
            :business-id="businessId"
            @close="setEditMode"
          />
          <FormMode
            v-else
            :resource="entry"
            :business-id="businessId"
            :is-creating="false"
            @saved="recordUpdated"
            @close="setViewMode"
          />
          <template v-if="!entry.isModifier">
            <hr class="my-5" />
            <ModifierLists
              :lists="lists"
              :business-id="businessId"
              :item="entry"
              @reordered="setModifierLists"
            />
          </template>
          <hr class="my-5" />
          <PrintingGroupsLists
            :lists="attachedPrintingGroups"
            :business-id="businessId"
            :item="entry"
            @updatePrintingGroups="fetchGroups"
          />
        </div>
      </LoadingZone>
    </div>
  </div>
</template>

<script>
import Items from "@/api/items";
import ItemConstructor from "@/constructors/Item";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import ViewMode from "@/components/items/View.vue";
import FormMode from "@/components/items/Form.vue";
import ModifierLists from "@/components/items/ModifierLists.vue";
import PrintingGroupsLists from "@/components/items/PrintingGroupsLists";
import { Toast } from "@/design-system";
import { propOr } from "ramda";
import { getBusinessById } from "@/utils/mixins";
import { fetchAttachedPrintingGroups } from "@/v2/repo/fetch-attached-printing-groups";
import { imageURLBuilder } from "@/v2/util/image-url-builder";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "ItemsEdit",
  components: {
    LoadingZone,
    FormMode,
    ViewMode,
    ModifierLists,
    PrintingGroupsLists,
  },
  mixins: [getBusinessById],
  props: {
    businessId: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    viewMode: true,
    entry: new ItemConstructor({ isModifier: false }),
    attachedPrintingGroups: [],
  }),
  computed: {
    lists() {
      return propOr([], "modifierLists", this.entry);
    },
    currency() {
      return propOr({}, "currency", this.business);
    },
    pageTitle() {
      return !this.entry.isModifier
        ? this.$t("label.edit_item")
        : this.$t("label.edit_modifier");
    },
    imgURL() {
      return imageURLBuilder(this.business.logo, 100, 100);
    },
  },
  created() {
    this.fetch();
    this.fetchGroups();
  },
  methods: {
    setViewMode() {
      this.viewMode = true;
    },
    setEditMode() {
      if (
        ![
          MenuManagementSourceByIdEnum.CHATFOOD,
          MenuManagementSourceByIdEnum.OMNIVORE,
          MenuManagementSourceByIdEnum.TEVALIS,
        ].includes(this.entry.source.id)
      ) {
        return;
      }

      this.viewMode = false;
    },
    setModifierLists(lists) {
      this.entry.modifierLists = lists;
    },
    async fetch() {
      this.loading = true;
      this.entry = await Items.find(this.businessId, this.itemId, {
        include: "modifiers.items",
      });
      this.loading = false;
    },

    async fetchGroups() {
      try {
        this.attachedPrintingGroups = await fetchAttachedPrintingGroups({
          businessId: this.businessId,
          itemId: this.itemId,
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      }
    },

    recordUpdated(entry) {
      Object.assign(this.entry, entry);
      this.viewMode = true;
    },
  },
};
</script>
