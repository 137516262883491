<template>
  <div class="p-4">
    <div class="row align-items-center cursor-pointer">
      <div class="col mb-4">
        <h5 class="m-0">{{ category.name }}</h5>
      </div>
      <div class="col-auto mb-4">
        <small>
          {{
            $t("message.available_for_x_of_y_outlets", {
              selectOutlets: availabilityLength,
              allOutlets: availabilities.length,
            })
          }}
        </small>
      </div>
    </div>
    <transition name="expand">
      <div>
        <Item
          v-for="(availability, index) in availabilities"
          :key="index"
          :business-id="businessId"
          :category-id="category.id"
          :availability="availability"
        />
      </div>
    </transition>
    <div class="clearfix mt-4">
      <button
        class="float-right btn btn-light"
        data-test="close"
        @click="$emit('close')"
      >
        {{ $t("label.close") }}
      </button>
    </div>
  </div>
</template>

<script>
import Item from "./Item.vue";

export default {
  name: "Availabilities",
  components: {
    Item,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    availabilities: {
      type: Array,
      required: true,
    },
  },
  computed: {
    availabilityLength() {
      return this.availabilities.filter((obj) => obj.isAvailable === true)
        .length;
    },
  },
};
</script>
