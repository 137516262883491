<template>
  <div>
    <div class="row align-items-center mb-2">
      <div class="col">
        <h5 class="mb-2">{{ $t("label.categories") }}</h5>
      </div>
      <div class="col-auto mb-2">
        <AtomIntegrationDisabledButton
          v-if="categories.length > 2"
          :enabled="hasMenuIntegration"
          :has-menu-integration="hasMenuIntegration"
          kind="blue-label"
          :label="$t('label.new_category').toString()"
        >
          <a
            href="#createCategory"
            class="d-inline-block text-primary text-decoration-none bold p-2 f-size-13"
          >
            {{ $t("label.new_category") }}
          </a>
        </AtomIntegrationDisabledButton>
      </div>
    </div>
    <a
      v-if="categories.length"
      href="#"
      data-test-setExpand
      class="d-inline-block text-dark text-decoration-none bold p-2 f-size-13"
      @click.prevent="toggleExpandAll"
    >
      <component
        :is="expandAll ? 'CompressAltSVG' : 'ExpandAltSVG'"
        class="w-12 mr-2"
      />
      {{ $t(`label.${expandAll ? "collapse" : "expand"}_all`) }}
    </a>
    <div v-if="categories.length" class="d-grid overflow-sm">
      <Draggable
        v-model="categoriesList"
        v-bind="dragOptions"
        handle=".moveHandle"
        @input="updateCategoriesOrder"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <Category
            v-for="(category, index) in categories"
            :key="category.id"
            :index="index"
            :has-menu-integration="hasMenuIntegration"
            :business-id="businessId"
            :menu-id="menuId"
            :category="category"
            :source-id="sourceId"
            :should-expand="expandAll"
            @deleted="categoryDeleted"
          />
        </transition-group>
      </Draggable>
    </div>
    <form class="mt-3" @submit.prevent="addCategory">
      <div class="input-group">
        <input
          id="createCategory"
          v-model="newCategory.name"
          type="text"
          class="form-control"
          :disabled="hasMenuIntegration"
          :placeholder="$t('message.new_category_followed_by_ellipses')"
        />
        <div class="input-group-append">
          <AtomIntegrationDisabledButton
            :enabled="hasMenuIntegration"
            :has-menu-integration="hasMenuIntegration"
            kind="default-no-radius-left"
            :class="{ 'btn-progress': adding }"
            :label="$t('label.create').toString()"
          >
            <button
              type="submit"
              :class="{ 'btn-progress': adding }"
              class="b-radius-left-0 input-group-text btn btn-primary text-white"
            >
              {{ $t("label.create") }}
            </button>
          </AtomIntegrationDisabledButton>
        </div>
      </div>
      <div v-if="newCategory.hasError('name')" class="error">
        {{ newCategory.firstError("name") }}
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import Menus from "@/api/menus";
import MenuCategoryConstructor from "@/constructors/MenuCategory";
import Category from "./Category.vue";
import CompressAltSVG from "@/assets/svg/icons/compress-alt.svg";
import ExpandAltSVG from "@/assets/svg/icons/expand-alt.svg";
import { debounce } from "@/utils/helpers/functions";
import Draggable from "vuedraggable";
import { pluck, filter } from "ramda";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { Toast } from "@/design-system";

export default {
  name: "Categories",
  components: {
    Category,
    Draggable,
    CompressAltSVG,
    ExpandAltSVG,
    AtomIntegrationDisabledButton,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    businessId: {
      type: String,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
    hasMenuIntegration: {
      type: Boolean,
      required: true,
    },
    sourceId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    adding: false,
    newCategory: new MenuCategoryConstructor(),
    drag: false,
    expandAll: true,
  }),
  computed: {
    categoriesList: {
      get() {
        return this.categories;
      },
      set(list) {
        this.$emit("reordered", list);
      },
    },
    dragOptions() {
      return {
        animation: 300,
        group: "categories",
        disabled: this.hasMenuIntegration,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    toggleExpandAll() {
      this.expandAll = !this.expandAll;
    },
    updateCategoriesOrder: debounce(async function (list) {
      await Menus.sortCategories(
        this.businessId,
        this.menuId,
        pluck("id", list)
      );
    }, 1500),
    async addCategory() {
      if (this.hasMenuIntegration) return;

      try {
        this.adding = true;

        const res = await Menus.addCategory(
          this.businessId,
          this.menuId,
          this.newCategory.toCreatePayload()
        );

        this.newCategory = new MenuCategoryConstructor();

        this.categories.push(res);
        new Toast().create({
          type: "success",
          text: this.$t("message.record_created"),
        });
      } catch (e) {
        this.newCategory.errors = e.errors;
      } finally {
        this.adding = false;
      }
    },
    categoryDeleted(categoryId) {
      this.categoriesList = filter(
        (obj) => obj.id !== categoryId,
        this.categoriesList
      );
    },
  },
};
</script>
