<template>
  <div class="clearfix">
    <div class="row align-items-center mb-4">
      <div class="col">
        <h5 class="m-0" data-test="modifierCount">
          {{ $t("label.modifiers") }}
          <small
            v-if="modifierList.modifiers.length"
            class="text-muted bold ml-1"
          >
            ({{ modifierList.modifiers.length }})
          </small>
        </h5>
      </div>
      <div class="col-auto">
        <AtomIntegrationDisabledButton
          kind="blue-label"
          class="d-inline-block"
          :enabled="!canEdit"
          :has-menu-integration="!canEdit"
          :label="$t('label.add_modifier').toString()"
        >
          <a
            href="#"
            data-test="btnOpenModal"
            class="d-inline-block text-primary text-decoration-none bold py-2 f-size-13"
            @click.prevent="showAddModifiers"
          >
            {{ $t("label.add_modifier") }}
          </a>
        </AtomIntegrationDisabledButton>
      </div>
    </div>
    <Draggable
      v-if="modifiers.length"
      v-model="modifiers"
      data-test="listContainer"
      v-bind="dragOptions"
      class="d-grid overflow-sm"
      @input="updateModifiersOrder"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <Modifier
          v-for="(modifier, index) in modifiers"
          :key="modifier.id"
          data-test="listItem"
          :has-menu-integration="!canEdit"
          :index="index"
          :business-id="businessId"
          :modifier-list-id="modifierList.id"
          :modifier="modifier"
          @deleted="modifierDeleted"
        />
      </transition-group>
    </Draggable>
    <div v-if="!modifiers.length" class="my-5">
      <p class="text-center" data-test="emptyListMessage">
        {{ $t("message.there_is_no_modifier_attached") }}
      </p>
      <p class="text-center">
        <a
          href="#"
          data-test="btnOpenModal"
          class="btn btn-dark"
          @click.prevent="showAddModifiers"
        >
          {{ $t("label.add_modifier") }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import ModifierLists from "@/api/modifier-lists";
import ModifiersList from "./List.vue";
import Modifier from "./Item.vue";
import { debounce } from "@/utils/helpers/functions";
import Draggable from "vuedraggable";
import { pluck } from "ramda";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "Modifiers",
  components: {
    Modifier,
    Draggable,
    AtomIntegrationDisabledButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    modifierList: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    drag: false,
  }),
  computed: {
    modifiers: {
      get() {
        return this.modifierList.modifiers;
      },
      set(modifiers) {
        this.$emit("reordered", modifiers);
      },
    },
    canEdit() {
      return (
        this.modifierList?.source?.isEditable &&
        this.modifierList?.source?.id === MenuManagementSourceByIdEnum.CHATFOOD
      );
    },
    dragOptions() {
      return {
        animation: 300,
        group: "modifier_lists",
        disabled: !this.canEdit,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    updateModifiersOrder: debounce(async function (modifiers) {
      await ModifierLists.sortModifiers(
        this.businessId,
        this.modifierList.id,
        pluck("id", modifiers)
      );
    }, 1500),
    showAddModifiers() {
      if (!this.canEdit) return;

      this.$modal.show(
        ModifiersList,
        {
          modifiersAdded: (modifiers) => {
            this.modifiers.push(...modifiers);
          },
          excludeModifiers: pluck("id", this.modifiers),
          businessId: this.businessId,
          modifierList: this.modifierList,
        },
        {
          name: "modifiers_list",
          adaptive: true,
          resizable: true,
          height: "auto",
          clickToClose: false,
        }
      );
    },
    modifierDeleted(modifierId) {
      this.modifierList.modifiers = this.modifierList.modifiers.filter(
        (obj) => obj.id !== modifierId
      );
    },
  },
};
</script>
