<template>
  <div class="d-flex bg-white">
    <a
      href="#"
      class="pl-1 p_top_18 pr-3"
      :class="{ 'cursor-default p_right_13': !items.length }"
      @click.prevent="expanded = !expanded"
    >
      <AngleRightSVG
        v-if="items.length"
        class="w-8 fill_muted"
        :class="{ rotateDown: expanded }"
      />
      <MinusSVG v-else class="w-11 fill_muted" />
    </a>
    <div class="w-100 border-top box-shadow-bottom">
      <div class="d-flex justify-content-between bg-white-light">
        <div class="category__line">
          <div v-if="!hasMenuIntegration" class="moveHandle pl-1 p_top_18">
            <LineHeightSVG class="w-15" />
          </div>
          <RenameCategory
            v-if="editMode"
            :business-id="businessId"
            :category="category"
            @close="closeEditMode"
            @updated="updatedName"
          />
          <div v-else class="editInline pt-2">
            <a
              href="#"
              class="text-dark d-flex align-items-center text-decoration-none editInline__link"
              @click.prevent="goToEdit()"
            >
              <span class="d-inline-block editInline__text">
                {{ category.name }}
              </span>
              <AtomIntegrationDisabledButton
                :enabled="hasMenuIntegration"
                :has-menu-integration="hasMenuIntegration"
                class="ml-2"
                kind="custom"
                :label="$t('label.edit').toString()"
              >
                <template #custom>
                  <PenSVG class="w-10" />
                </template>
                <span
                  v-tooltip="$t('label.edit')"
                  class="editInline__icon px-2"
                >
                  <PenSVG class="w-10" />
                </span>
              </AtomIntegrationDisabledButton>
            </a>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-between pt-2 pl-3 pb-2"
        >
          <div class="minw-80 text-right pr-2">
            <span v-if="items.length" class="bold f-size-11">
              {{ $t("label.items") }}
              ({{ items.length }})
            </span>
            <AtomIntegrationDisabledButton
              v-else
              :enabled="hasMenuIntegration"
              :has-menu-integration="hasMenuIntegration"
              class="ml-2"
              kind="add-items"
              :label="$t('label.add_items').toString()"
            >
              <a
                href="#"
                class="d-inline-block bold py-2 f-size-11 text-decoration-none text-primary"
                @click.prevent="addItems"
              >
                {{ $t("label.add_items") }}
              </a>
            </AtomIntegrationDisabledButton>
          </div>
          <MenuDropdown position="rightBottom">
            <template #button>
              <span
                v-tooltip="{
                  autoHide: false,
                  trigger: 'click hover',
                  content: `${$t('label.actions')}`,
                }"
                class="text-dark dropCustom__caret--down py-1 px-4"
              >
                <EllipsisVSVG class="w-5 fill_muted" />
              </span>
            </template>
            <template #list>
              <AtomIntegrationDisabledButton
                :enabled="hasMenuIntegration"
                :has-menu-integration="hasMenuIntegration"
                kind="black-label"
                :label="$t('label.add_items').toString()"
              >
                <a
                  href="#"
                  class="dropCustom__menu--item"
                  @click.prevent="addItems"
                >
                  {{ $t("label.add_items") }}
                </a>
              </AtomIntegrationDisabledButton>
              <AtomIntegrationDisabledButton
                :enabled="hasMenuIntegration"
                :has-menu-integration="hasMenuIntegration"
                kind="black-label"
                :label="$t('label.outlets').toString()"
              >
                <a
                  href="#"
                  class="dropCustom__menu--item"
                  @click.prevent="openAvailabilities"
                >
                  {{ $t("label.outlets") }}
                </a>
              </AtomIntegrationDisabledButton>
              <hr class="my-2" />
              <AtomIntegrationDisabledButton
                :enabled="hasMenuIntegration"
                :has-menu-integration="hasMenuIntegration"
                kind="red-label"
                :label="$t('label.delete').toString()"
              >
                <button
                  type="button"
                  class="dropCustom__menu--item text-danger"
                  @click.prevent="destroy"
                >
                  {{ $t("label.delete") }}
                </button>
              </AtomIntegrationDisabledButton>
            </template>
          </MenuDropdown>
        </div>
      </div>
      <transition v-if="expanded" name="expand">
        <div v-if="items.length" class="pl-4">
          <Draggable
            v-if="items"
            v-model="items"
            v-bind="dragOptions"
            handle=".moveHandle"
            @input="updateItemsOrder"
            @start="drag = true"
            @end="drag = false"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <Item
                v-for="(item, index) in items"
                :key="item.id"
                :index="index"
                :has-menu-integration="hasMenuIntegration"
                :business-id="businessId"
                :category-id="category.id"
                :item="item"
                @deleted="itemDeleted"
              />
            </transition-group>
          </Draggable>
          <a
            v-if="!hasMenuIntegration"
            href="#"
            class="d-inline-block bold text-primary text-decoration-none py-3 mb-4 f-size-12"
            @click.prevent="addItems"
          >
            {{ $t("label.add_items") }}
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import Menus from "@/api/menus";
import Item from "./Item.vue";
import ItemsList from "./ItemsList.vue";
import RenameCategory from "./RenameCategory.vue";
import LineHeightSVG from "@/assets/svg/icons/line-height.svg";
import AngleRightSVG from "@/assets/svg/icons/angle-right.svg";
import MinusSVG from "@/assets/svg/icons/minus.svg";
import PenSVG from "@/assets/svg/icons/pen.svg";
import EllipsisVSVG from "@/assets/svg/icons/ellipsis-v.svg";
import Availabilities from "@/components/menus/availabilities/Index.vue";
import MenuDropdown from "@/components/helpers/MenuDropdown";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { debounce } from "@/utils/helpers/functions";
import Draggable from "vuedraggable";
import { pluck, propOr } from "ramda";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { Toast } from "@/design-system";

export default {
  name: "Category",
  components: {
    Item,
    Draggable,
    LineHeightSVG,
    AngleRightSVG,
    MinusSVG,
    MenuDropdown,
    PenSVG,
    AtomIntegrationDisabledButton,
    EllipsisVSVG,
    RenameCategory,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    shouldExpand: {
      type: Boolean,
      default: false,
    },
    hasMenuIntegration: {
      type: Boolean,
      required: true,
    },
    sourceId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    editMode: false,
    drag: false,
    expanded: true,
  }),
  computed: {
    items: {
      get() {
        return propOr([], "items", this.category);
      },
      set(items) {
        this.category.items = items;
      },
    },
    dragOptions() {
      return {
        animation: 300,
        group: this.category.id,
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    shouldExpand(val) {
      this.expanded = val;
    },
  },
  methods: {
    goToEdit() {
      if (this.hasMenuIntegration) return;
      this.editMode = true;
    },
    setExpanded() {
      this.expanded = !this.expanded;
    },
    closeEditMode() {
      this.editMode = false;
    },
    updatedName(categoryName) {
      this.editMode = false;
      this.category.name = categoryName;
    },
    openAvailabilities() {
      if (this.hasMenuIntegration) return;

      this.$modal.show(
        Availabilities,
        {
          businessId: this.businessId,
          category: this.category,
          availabilities: this.category.availabilities,
        },
        {
          name: "availabilities",
          height: "auto",
          adaptive: true,
          resizable: true,
        }
      );
    },
    updateItemsOrder: debounce(async function (list) {
      await Menus.sortItemsInCategory(
        this.businessId,
        this.category.id,
        pluck("id", list)
      );
    }, 1500),
    addItems() {
      this.$modal.show(
        ItemsList,
        {
          itemsAdded: (items) => {
            this.category.items = items;
          },
          excludeItems: pluck("id", this.items),
          businessId: this.businessId,
          category: this.category,
          sourceId: this.sourceId,
        },
        {
          name: "items_list",
          height: "auto",
          adaptive: true,
          resizable: true,
          clickToClose: false,
        }
      );
    },
    destroy() {
      const callback = async () => {
        try {
          await Menus.removeCategory(this.businessId, this.category.id);
          this.$emit("deleted", this.category.id);
          new Toast().create({
            type: "success",
            text: this.$t("message.record_deleted"),
          });
        } catch (e) {
          new Toast().create({
            type: "error",
            text: this.$t("message.generic_failure"),
          });
        } finally {
          this.$modal.hide("dialog");
        }
      };
      destroyDialog(this, callback, { resourceName: this.category.name });
    },
    itemDeleted(itemId) {
      this.category.items = this.items.filter((obj) => obj.id !== itemId);
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  &__line {
    display: flex;
    width: 100%;
    min-width: 13.75rem;
    min-height: 3.5625rem;
    align-items: center;
    padding-bottom: 0.5rem;
  }
}
</style>
