<template>
  <LoadingZone :loading="isLoading" :dom-visibility="false">
    <div v-if="item" class="item-page">
      <div
        v-if="item.photo"
        :style="`background-image: url('${item.photo}?fm=jpeg&h=530&q=80&fit=crop&crop=center');`"
        class="item-page__photo"
      >
        <a
          href="#"
          data-test="close-item"
          class="item-page__btn-back"
          @click.prevent="$emit('closeItem')"
        >
          <ChevronLeftSVG class="w-7" />
        </a>
      </div>
      <div class="p-3 bg-white box-shadow-1">
        <a
          v-if="!item.photo"
          href="#"
          data-test="close-item"
          class="item-page__btn-back item-page__btn-back--static"
          @click.prevent="$emit('closeItem')"
        >
          <ChevronLeftSVG class="w-7" />
        </a>
        <h4 class="mt-2 mb-3">{{ item.name }}</h4>
        <p v-if="item.description" class="text-greyLight f-size-13">
          {{ item.description }}
        </p>
        <Money
          tag="p"
          :amount="item.amount"
          :business-id="item.businessId"
          class="m-0 f-w-600"
        />
      </div>
      <div v-if="hasModifierList" data-test="modifierList">
        <ModifierList
          v-for="(modifierList, index) in item.modifierLists"
          :key="index"
          :modifier-list="modifierList"
        />
      </div>
      <div v-else class="px-3 py-5 text-center" data-test="modifierList-empty">
        <p>{{ $t("message.there_is_no_modifier_list_attached") }}</p>
        <router-link
          :to="{
            name: 'items.edit',
            params: { itemId: item.id },
          }"
          target="_blank"
          rel="noopener noreferrer"
          class="btn btn-dark"
        >
          {{ $t("label.add_modifier_list") }}
        </router-link>
      </div>
    </div>
  </LoadingZone>
</template>

<script>
import Money from "@/components/helpers/Money.vue";
import ModifierList from "./ModifierList.vue";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import ChevronLeftSVG from "@/assets/svg/icons/chevron-left.svg";
import { isNil, compose, length, propOr, equals, not } from "ramda";

export default {
  name: "Item",
  components: {
    Money,
    ModifierList,
    LoadingZone,
    ChevronLeftSVG,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: true,
  }),
  computed: {
    hasModifierList() {
      return compose(
        not,
        equals(0),
        length,
        propOr([], "modifierLists")
      )(this.item);
    },
  },
  watch: {
    item(val) {
      this.isLoading = isNil(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-page {
  &__photo {
    position: relative;
    width: 100%;
    height: 265px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: var(--color-white-1);
  }

  &__btn-back {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    padding-right: 3px;
    background: var(--color-white);

    &--static {
      position: relative;
      top: auto;
      left: auto;
      margin-bottom: 1rem;
      border: solid 1px var(--color-grey);
      background: var(--color-white-1);
    }
  }
}
</style>
