<template>
  <label class="modifier-item__label" @click.prevent="quantity += 1">
    <div class="row">
      <div class="col-auto text-right w-35 p-0">
        <span v-if="quantity > 0" class="text-primary bold f-size-13">
          {{ quantity }}
          <small>x</small>
        </span>
      </div>
      <div class="col">
        {{ modifier.name }}
        <Money
          v-if="modifier.amount > 0"
          class="text-greyLight f-size-11"
          :amount="modifier.amount"
          :business-id="modifier.businessId"
        />
      </div>
    </div>
  </label>
</template>

<script>
import Money from "@/components/helpers/Money.vue";

export default {
  name: "MultipleSelector",
  components: { Money },
  props: {
    modifierListId: {
      type: String,
      required: true,
    },
    modifier: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    quantity: 0,
  }),
};
</script>
