<template>
  <LoadingZone :loading="loading" class="p-4">
    <h5 class="text-center mb-4" data-test-title>
      <span class="bold">{{ $t("message.add_modifier_to") }} </span>
      <strong class="text-primary"> {{ modifierList.name }}</strong>
    </h5>
    <input
      v-if="availableModifiers.length"
      v-model="search"
      type="text"
      data-test-inputSearch
      autofocus="autofocus"
      class="form-control mb-3"
      :placeholder="
        $t('message.search_for_x_followed_by_ellipses', {
          search: $t('label.modifier'),
        })
      "
    />
    <ul class="p-0 m-0" style="max-height: calc(100vh - 320px); overflow: auto">
      <li
        v-for="(modifier, index) in filteredModifiers"
        :key="modifier.id"
        class="border-bottom"
        data-test-modifierItem
      >
        <div class="customCheckbox">
          <input
            :id="index"
            v-model="addModifiers"
            type="checkbox"
            :value="modifier"
            data-test-inputCheckbox
            class="customCheckbox__input"
          />
          <label :for="index" class="customCheckbox__text py-2">
            <span class="customCheckbox__square"></span>
            <div class="row m-0">
              <div class="col p-0">
                <span :title="createdAtLabel(modifier.createdAt)">
                  {{ modifier.name }}
                </span>
              </div>
              <div class="col-auto">
                <Money
                  :amount="modifier.amount"
                  :business-id="businessId"
                  tag="small"
                />
              </div>
            </div>
          </label>
        </div>
      </li>
    </ul>

    <div
      v-if="availableModifiers.length && !filteredModifiers.length"
      class="my-5 text-center"
    >
      {{ $t("message.there_is_no_modifier_matching") }}
    </div>

    <div v-if="!availableModifiers.length" class="my-5">
      <p class="text-center" data-test-noModifier>
        {{ $t("message.there_is_no_modifier_attached") }}
      </p>
      <p class="text-center">
        <router-link
          data-test-btnCreate
          class="btn btn-dark"
          :to="{ name: 'items.create' }"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("label.create_modifier") }}
        </router-link>
      </p>
    </div>
    <div class="clearfix mt-4">
      <button data-test-btnCancel class="btn btn-light" @click="$emit('close')">
        {{ $t("label.cancel") }}
      </button>
      <button
        data-test-btnAdd
        class="btn btn-primary float-right"
        :class="{ 'btn-progress': adding }"
        :disabled="!addModifiers.length"
        @click="save"
      >
        {{ $tc("label.add_x_modifiers", addModifiers.length) }}
      </button>
    </div>
  </LoadingZone>
</template>

<script>
import Money from "@/components/helpers/Money.vue";
import Items from "@/api/items";
import ModifierLists from "@/api/modifier-lists";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import { pluck, isEmpty, is, prop, sortWith, ascend } from "ramda";
import { mapGetters } from "vuex";
import { date } from "@/utils/filters";
import { Toast } from "@/design-system";

export default {
  name: "ModifiersList",
  components: {
    LoadingZone,
    Money,
  },
  props: {
    modifiersAdded: {
      type: Function,
      default: () => {},
    },
    excludeModifiers: {
      type: Array,
      default: null,
    },
    businessId: {
      type: String,
      required: true,
    },
    modifierList: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    search: "",
    loading: false,
    adding: false,
    modifiers: [],
    addModifiers: [],
  }),
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    business() {
      return Object.values(this.businesses).find(
        (obj) => obj.id === this.businessId
      );
    },
    availableModifiers() {
      if (!is(Array, this.excludeModifiers)) return this.modifiers;

      return this.modifiers.filter(
        (obj) => !this.excludeModifiers.includes(obj.id)
      );
    },
    filteredModifiers() {
      if (isEmpty(this.search)) return this.availableModifiers;

      const query = this.search.trim().toLowerCase();
      return this.availableModifiers.filter((obj) =>
        obj.name.toLowerCase().includes(query)
      );
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    createdAtLabel(createdAt) {
      if (!createdAt) return;

      return `${this.$t("label.created_at")}: ${date(
        createdAt,
        "MMM DD, YYYY - h:mm A"
      )}`;
    },
    async fetch() {
      this.loading = true;

      const res = await Items.get(this.businessId, {
        is_modifier: 1,
        limit: 9999,
        source: this.modifierList.source.id,
      });

      this.modifiers = sortWith([ascend(prop("name"))], res.data);

      this.loading = false;
    },
    async save() {
      this.adding = true;

      this.$analytics.trackEvent("Modifier associated to list", {
        business_id: this.businessId,
        business_name: this.business.name,
        event_category: "menu_builder",
      });

      try {
        await ModifierLists.attachModifiers(
          this.businessId,
          this.modifierList.id,
          pluck("id", this.addModifiers)
        );
        this.modifiersAdded(this.addModifiers);
        this.$emit("close");

        new Toast().create({
          type: "success",
          text: this.$t("message.records_linked"),
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.adding = false;
      }
    },
  },
};
</script>
