<template>
  <div class="d-flex bg-white">
    <div class="w-100 border-top box-shadow-bottom">
      <div class="d-flex justify-content-between">
        <div class="py-3 w-100">
          <div class="d-flex justify-content-between">
            <span class="w-100 py-1 minw-100 d-flex flex-column"
              >{{ group.name }}
              <small>{{ group.outletName }}</small>
            </span>
            <div
              class="d-flex align-items-center justify-content-end minw-180 text-right"
            >
              <div>
                <a href="#" @click.prevent="showItemList()">
                  <EyeSVG class="w-14 fill_muted" />
                </a>
                <AtomIntegrationDisabledButton
                  :enabled="hasMenuIntegration"
                  :has-menu-integration="hasMenuIntegration"
                  class="ml-2"
                  kind="custom"
                  :label="$t('label.remove').toString()"
                >
                  <template #custom>
                    <TimesCircleSVG class="w-12 fill_muted" />
                  </template>
                  <a
                    v-tooltip="$t('label.remove')"
                    href="#"
                    class="py-1 px-3"
                    @click.prevent="destroy"
                  >
                    <TimesCircleSVG class="w-12 fill_muted" />
                  </a>
                </AtomIntegrationDisabledButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EyeSVG from "@/assets/svg/icons/eye.svg";
import PrintingGroupListItem from "./PrintingGroupListItem";
import TimesCircleSVG from "@/assets/svg/icons/times-circle.svg";
import { detachPrintingGroups } from "@/v2/repo/detach-printing-groups";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";
import { Toast } from "@/design-system";

export default {
  name: "PrintingGroupList",
  components: {
    EyeSVG,
    TimesCircleSVG,
    AtomIntegrationDisabledButton,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    hasMenuIntegration: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editMode: false,
  }),
  computed: {
    blockActions() {
      return (
        this.hasMenuIntegration ||
        ![
          MenuManagementSourceByIdEnum.CHATFOOD,
          MenuManagementSourceByIdEnum.OMNIVORE,
        ].includes(this.item.source.id)
      );
    },
  },
  methods: {
    showItemList() {
      this.$modal.show(
        PrintingGroupListItem,
        {
          businessId: this.businessId,
          groupName: this.group.name,
          itemsIds: this.group.items,
        },
        {
          name: "printing_groups_list_item",
          adaptive: true,
          resizable: true,
          height: "auto",
          clickToClose: false,
        }
      );
    },
    goToEdit() {
      if (this.blockActions) return;
      this.editMode = true;
    },
    destroy() {
      const callback = async () => {
        try {
          await detachPrintingGroups({
            businessId: this.businessId,
            itemId: this.item.id,
            groupIds: [this.group.id],
          });

          this.$emit("deleted", this.group.id);
          this.$modal.hide("dialog");

          new Toast().create({
            type: "success",
            text: this.$t("message.record_unlinked"),
          });
        } catch (e) {
          new Toast().create({
            type: "error",
            text: this.$t("message.generic_failure"),
          });
        }
      };
      destroyDialog(this, callback, { resourceName: this.group.name });
    },
  },
};
</script>
