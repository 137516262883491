<template>
  <ul class="navbarTabs navbarTabs__top">
    <li
      v-for="(category, index) in categories"
      :key="index"
      class="navbarTabs__item"
    >
      <a :href="`#category${index}`" class="navbarTabs__link">
        {{ category.name }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Navigator",
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.navbarTabs {
  &__top {
    position: sticky;
    top: 0;
    display: flex;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 0.25rem;
    overflow: auto;
    list-style: none;
    z-index: 1;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.13);
    background-color: var(--color-white);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-white);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #eaeaea;

    &:hover {
      background-color: #ddd;
    }
  }

  &__item {
    padding: 0 0 0 0.5rem;

    &:first-child .navbarTabs__link,
    .navbarTabs__link:hover,
    .navbarTabs__link:focus,
    .navbarTabs__link--active {
      color: var(--color-white);
      text-decoration: none;
      background-color: var(--color-brand-primary);
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    color: var(--color-grey);
    background-color: var(--color-white-1);
    white-space: nowrap;
    padding: 0.25rem 0.75rem;
    transition: all 0.2s ease-out;
  }
}
</style>
