<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col">
        <h5 class="m-0">
          {{ $t("c.items.printing_groups_list.title") }}
          <small v-if="lists.length" class="text-muted bold ml-1">
            ({{ lists.length }})
          </small>
        </h5>
      </div>
      <div class="col-auto">
        <AtomIntegrationDisabledButton
          :enabled="hasMenuIntegration"
          :has-menu-integration="hasMenuIntegration"
          kind="blue-label"
          :label="
            $t('c.items.printing_groups_list.add_printing_group').toString()
          "
        >
          <a
            href="#"
            class="d-inline-block text-primary text-decoration-none bold py-2 f-size-13"
            @click.prevent="showAddLists"
          >
            {{ $t("c.items.printing_groups_list.add_printing_group") }}
          </a>
        </AtomIntegrationDisabledButton>
      </div>
    </div>
    <div
      v-if="lists.length"
      class="d-grid overflow-sm"
      data-test="listContainer"
    >
      <PrintingGroupList
        v-for="(list, index) in lists"
        :key="list.id"
        :index="index"
        :has-menu-integration="hasMenuIntegration"
        :group="list"
        :business-id="businessId"
        :item="item"
        @deleted="listDeleted"
      />
    </div>
    <div v-else class="my-5">
      <p class="text-center">
        {{ $t("c.items.printing_groups_list.no_printing_group_attached") }}
      </p>
      <p v-if="!hasMenuIntegration" class="text-center">
        <a href="#" class="btn btn-dark" @click.prevent="showAddLists">
          {{ $t("c.items.printing_groups_list.add_printing_group") }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import PrintingGroupListsList from "./PrintingGroupListsList.vue";
import PrintingGroupList from "./PrintingGroupList.vue";
import { pluck, indexOf, find } from "ramda";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "PrintingGroupsListsToAdd",
  components: {
    PrintingGroupList,
    AtomIntegrationDisabledButton,
  },
  props: {
    lists: {
      type: Array,
      default: () => [],
    },
    businessId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasMenuIntegration() {
      return ![
        MenuManagementSourceByIdEnum.CHATFOOD,
        MenuManagementSourceByIdEnum.OMNIVORE,
      ].includes(this.item.source.id);
    },
  },
  methods: {
    showAddLists() {
      this.$modal.show(
        PrintingGroupListsList,
        {
          updatePrintingGroups: () => this.$emit("updatePrintingGroups"),
          excludeLists: pluck("id", this.lists),
          businessId: this.businessId,
          item: this.item,
        },
        {
          name: "printing_groups_list",
          adaptive: true,
          resizable: true,
          height: "auto",
          clickToClose: false,
        }
      );
    },
    listDeleted(listId) {
      const index = indexOf(
        find((obj) => obj.id === listId, this.lists),
        this.lists
      );
      this.lists.splice(index, 1);
    },
  },
};
</script>
