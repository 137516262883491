var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex bg-white border-top box-shadow-bottom pl-1"},[(!_vm.hasMenuIntegration)?_c('div',{staticClass:"moveHandle pl-1 p_top_18"},[_c('LineHeightSVG',{staticClass:"w-15"})],1):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100 py-3"},[_c('div',{staticClass:"row w-100 p_right_14"},[_c('div',{staticClass:"col minw-120"},[_vm._v(_vm._s(_vm.item.name))]),_c('div',{staticClass:"col-auto pr-3 text-right"},[_c('Money',{attrs:{"tag":"small","amount":_vm.item.amount,"business-id":_vm.businessId}})],1)]),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        autoHide: false,
        trigger: 'click hover',
        content: ("" + (_vm.$t('label.view'))),
      }),expression:"{\n        autoHide: false,\n        trigger: 'click hover',\n        content: `${$t('label.view')}`,\n      }"}],staticClass:"py-1 px-3",attrs:{"to":{
        name: 'items.edit',
        params: { itemId: _vm.item.id },
      },"target":"_blank","rel":"noopener noreferrer"}},[_c('EyeSVG',{staticClass:"w-14 fill_muted"})],1),_c('AtomIntegrationDisabledButton',{staticClass:"ml-2",attrs:{"kind":"custom","label":_vm.$t('label.view').toString(),"enabled":_vm.hasMenuIntegration,"has-menu-integration":_vm.hasMenuIntegration},scopedSlots:_vm._u([{key:"custom",fn:function(){return [_c('TimesCircleSVG',{staticClass:"w-12 fill_muted"})]},proxy:true}])},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('label.remove')),expression:"$t('label.remove')"}],staticClass:"py-1 px-3",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.destroy.apply(null, arguments)}}},[_c('TimesCircleSVG',{staticClass:"w-12 fill_muted"})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }