<template>
  <Listing />
</template>

<script>
import ModifierLists from "@/api/modifier-lists";
import ListingDirector from "@/builders/ListingDirector";

export default {
  name: "ModifierLists",
  components: {
    Listing: new ListingDirector().modifierListListing(),
  },
  provide() {
    return {
      getEntries: this.fetch,
      destroyItem: this.destroy,
    };
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async fetch(params) {
      return ModifierLists.get(this.businessId, params);
    },
    async destroy({ id }) {
      return ModifierLists.destroy(this.businessId, id);
    },
  },
};
</script>
