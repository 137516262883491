import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import {
  IFetchAttachedPrintingGroupsRequest,
  IFetchAttachedPrintingGroupsResponse,
  IFetchAttachedPrintingGroupsContract,
  IFetchAttachedPrintingGroupsBodyParams,
} from "./types";

export class FetchAttachedPrintingGroupsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchAttachedPrintingGroupsError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchAttachedPrintingGroups(
  request: IFetchAttachedPrintingGroupsRequest
): Promise<IFetchAttachedPrintingGroupsResponse> {
  let response: AxiosResponse<IFetchAttachedPrintingGroupsContract>;

  try {
    const params: IFetchAttachedPrintingGroupsBodyParams = {
      item_id: request.itemId,
    };
    response = await http.get(
      `/printing/business/${request.businessId}/groups-by-item`,
      {
        params,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchAttachedPrintingGroupsError(error);
  }

  return response.data.map((obj: any) => ({
    id: obj.id,
    name: obj.name,
    outletName: obj.outlet_name,
    items: obj.items,
  }));
}
