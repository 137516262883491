<template>
  <div
    class="d-flex bg-white justify-content-between border-top box-shadow-bottom pl-2"
  >
    <span v-if="!hasMenuIntegration" class="pl-0 pt-3 pr-3 moveHandle">
      <LineHeightSVG />
    </span>
    <div
      class="d-flex w-100 align-items-center justify-content-between minh-55"
    >
      <span class="minw-200 w-100">{{ modifier.name }}</span>
      <OverridePrice
        v-if="editMode"
        :business-id="businessId"
        :modifier-list-id="modifierListId"
        :modifier="modifier"
        @close="editMode = false"
      />
      <div v-else data-test="viewMode" class="editInline">
        <a
          href="#"
          class="text-dark d-flex align-items-center justify-content-end text-decoration-none editInline__link p-2 p_right_0"
          @click.prevent="editModeVerify"
        >
          <Money
            :amount="modifier.amount"
            :business-id="businessId"
            class="d-inline-block mr-2 editInline__text"
            data-test="price"
          />
          <AtomIntegrationDisabledButton
            :enabled="hasMenuIntegration"
            :has-menu-integration="hasMenuIntegration"
            class="px-2"
            kind="custom"
            :label="$t('label.remove').toString()"
          >
            <template #custom>
              <PenSVG class="w-10" />
            </template>
            <span class="editInline__icon px-2">
              <PenSVG class="w-10" />
            </span>
          </AtomIntegrationDisabledButton>
        </a>
      </div>
      <router-link
        v-tooltip="{
          autoHide: false,
          trigger: 'click hover',
          content: `${$t('label.view')}`,
        }"
        :to="{
          name: 'items.edit',
          params: { itemId: modifier.id },
        }"
        target="_blank"
        class="py-1 px-3"
      >
        <EyeSVG class="w-14 fill_muted" />
      </router-link>
      <AtomIntegrationDisabledButton
        :enabled="hasMenuIntegration"
        :has-menu-integration="hasMenuIntegration"
        class="ml-2"
        kind="custom"
        :label="$t('label.remove').toString()"
      >
        <template #custom>
          <TimesCircleSVG class="w-12 fill_muted" />
        </template>
        <a href="#" class="py-1 px-3" @click.prevent="destroy">
          <TimesCircleSVG class="w-12 fill_muted" />
        </a>
      </AtomIntegrationDisabledButton>
    </div>
  </div>
</template>

<script>
import ModifierLists from "@/api/modifier-lists";
import OverridePrice from "./OverridePrice.vue";
import Money from "@/components/helpers/Money.vue";
import EyeSVG from "@/assets/svg/icons/eye.svg";
import LineHeightSVG from "@/assets/svg/icons/line-height.svg";
import PenSVG from "@/assets/svg/icons/pen.svg";
import TimesCircleSVG from "@/assets/svg/icons/times-circle.svg";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";
import { Toast } from "@/design-system";

export default {
  name: "Modifier",
  components: {
    Money,
    OverridePrice,
    AtomIntegrationDisabledButton,
    EyeSVG,
    LineHeightSVG,
    PenSVG,
    TimesCircleSVG,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    modifierListId: {
      type: String,
      required: true,
    },
    modifier: {
      type: Object,
      required: true,
    },
    hasMenuIntegration: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    editMode: false,
  }),

  methods: {
    editModeVerify() {
      if (
        this.hasMenuIntegration ||
        (this.modifier.source &&
          this.modifier.source.id !== MenuManagementSourceByIdEnum.CHATFOOD)
      ) {
        return;
      }
      this.editMode = true;
    },
    destroy() {
      const callback = async () => {
        try {
          await ModifierLists.detachModifiers(
            this.businessId,
            this.modifierListId,
            [this.modifier.id]
          );
          this.$emit("deleted", this.modifier.id);

          new Toast().create({
            type: "success",
            text: this.$t("message.record_unlinked"),
          });
        } catch (e) {
          new Toast().create({
            type: "error",
            text: this.$t("message.generic_failure"),
          });
        } finally {
          this.$modal.hide("dialog");
        }
      };
      destroyDialog(this, callback, { resourceName: this.modifier.name });
    },
  },
};
</script>
