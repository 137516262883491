<template>
  <div class="d-flex align-items-center editInline w-100 pt-2">
    <input
      v-model="categoryName"
      type="text"
      class="editInline__input form-control"
      @keyup.enter="rename"
      @keyup.esc="cancel"
    />
    <div class="d-flex">
      <a
        v-tooltip="{
          autoHide: false,
          trigger: 'click hover',
          content: $t('label.save'),
        }"
        href="#"
        class="editInline__icon px-2"
        :class="{ 'btn-progress': loading }"
        @click.prevent="rename"
      >
        <CheckSVG class="w-13" />
      </a>
      <a
        v-tooltip="{
          autoHide: false,
          trigger: 'click hover',
          content: $t('label.cancel'),
        }"
        href="#"
        class="editInline__icon px-2"
        @click.prevent="cancel"
      >
        <TimesSVG class="w-10" />
      </a>
    </div>
  </div>
</template>

<script>
import Menus from "@/api/menus";
import CheckSVG from "@/assets/svg/icons/check.svg";
import TimesSVG from "@/assets/svg/icons/times.svg";
import { Toast } from "@/design-system";
import { pathOr } from "ramda";

export default {
  name: "RenameCategory",
  components: {
    CheckSVG,
    TimesSVG,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    categoryName: null,
  }),
  created() {
    this.categoryName = this.category.name || null;
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    async rename() {
      try {
        this.loading = true;
        const categoryName = this.categoryName;
        await Menus.renameCategory(this.businessId, this.category.id, {
          name: categoryName,
        });

        this.$emit("updated", categoryName);
        new Toast().create({
          type: "success",
          text: this.$t("message.record_updated"),
        });
      } catch (e) {
        const msg = pathOr(
          this.$t("message.generic_failure"),
          ["errors", "name", 0],
          e
        );

        new Toast().create({
          type: "error",
          text: msg,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
