<template>
  <Listing />
</template>

<script>
import Items from "@/api/items";
import ListingDirector from "@/builders/ListingDirector";

export default {
  name: "Items",
  components: {
    Listing: new ListingDirector().itemListing(),
  },
  provide() {
    return {
      getEntries: this.fetch,
      destroyItem: this.destroy,
    };
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async fetch(params) {
      return Items.get(this.businessId, params);
    },
    async destroy({ id }) {
      return Items.destroy(this.businessId, id);
    },
  },
};
</script>
