<template>
  <div class="clearfix">
    <LoadingZone :loading="loading" :dom-visibility="false">
      <div class="row align-items-center mb-4">
        <div class="col">
          <h5 class="m-0" data-test="items-amount">
            {{ $t("label.items_attached") }}
            <small v-if="items.length" class="text-muted bold ml-1">
              ({{ items.length }})
            </small>
          </h5>
        </div>
        <div class="col-auto">
          <AtomIntegrationDisabledButton
            :enabled="hasMenuIntegration"
            :has-menu-integration="hasMenuIntegration"
            class="d-inline-block"
            kind="blue-label"
            :label="$t('label.attach_items').toString()"
          >
            <a
              href="#"
              data-test="btn-open-modal"
              class="d-inline-block text-primary text-decoration-none bold py-2 f-size-13"
              @click.prevent="showAddItems"
            >
              {{ $t("label.attach_items") }}
            </a>
          </AtomIntegrationDisabledButton>
        </div>
      </div>
      <div v-if="items.length" class="d-grid overflow-sm">
        <Item
          v-for="item in filterItems"
          :key="item.id"
          data-test="listItem"
          :business-id="businessId"
          :modifier-list-id="modifierList.id"
          :item="item"
          :has-menu-integration="hasMenuIntegration"
          @deleted="itemDeleted"
        />
      </div>
      <div v-else class="my-5">
        <p class="text-center">
          {{ $t("message.there_is_no_item_attached") }}
        </p>
        <p class="text-center">
          <a
            href="#"
            class="btn btn-dark"
            data-test="btn-open-modal"
            @click.prevent="showAddItems"
          >
            {{ $t("label.attach_items") }}
          </a>
        </p>
      </div>
    </LoadingZone>
  </div>
</template>

<script>
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import ModifierLists from "@/api/modifier-lists";
import ItemsList from "./List.vue";
import Item from "./Item.vue";
import { pluck, sortWith, ascend, prop } from "ramda";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "Items",
  components: {
    Item,
    LoadingZone,
    AtomIntegrationDisabledButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    modifierList: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    items: [],
  }),
  computed: {
    filterItems() {
      return sortWith([ascend(prop("name"))], this.items);
    },
    hasMenuIntegration() {
      return (
        this.modifierList.source &&
        this.modifierList.source.id !== MenuManagementSourceByIdEnum.CHATFOOD
      );
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    showAddItems() {
      if (this.hasMenuIntegration) return;

      this.$modal.show(
        ItemsList,
        {
          itemsAdded: (items) => {
            this.items.push(...items);
          },
          excludeItems: pluck("id", this.items),
          businessId: this.businessId,
          modifierList: this.modifierList,
        },
        {
          name: "items_list",
          adaptive: true,
          resizable: true,
          height: "auto",
          clickToClose: false,
        }
      );
    },
    async fetch() {
      this.loading = true;
      this.items = await ModifierLists.getItems(
        this.businessId,
        this.modifierList.id
      );
      this.loading = false;
    },
    itemDeleted(itemId) {
      this.items = this.items.filter((obj) => obj.id !== itemId);
    },
  },
};
</script>
