<template>
  <div class="d-flex bg-white border-top box-shadow-bottom pl-1">
    <div v-if="!hasMenuIntegration" class="moveHandle pl-1 p_top_18">
      <LineHeightSVG class="w-15" />
    </div>
    <div class="d-flex align-items-center justify-content-between w-100 py-3">
      <div class="row w-100 p_right_14">
        <div class="col minw-120">{{ item.name }}</div>
        <div class="col-auto pr-3 text-right">
          <Money tag="small" :amount="item.amount" :business-id="businessId" />
        </div>
      </div>
      <router-link
        v-tooltip="{
          autoHide: false,
          trigger: 'click hover',
          content: `${$t('label.view')}`,
        }"
        :to="{
          name: 'items.edit',
          params: { itemId: item.id },
        }"
        target="_blank"
        rel="noopener noreferrer"
        class="py-1 px-3"
      >
        <EyeSVG class="w-14 fill_muted" />
      </router-link>
      <AtomIntegrationDisabledButton
        class="ml-2"
        kind="custom"
        :label="$t('label.view').toString()"
        :enabled="hasMenuIntegration"
        :has-menu-integration="hasMenuIntegration"
      >
        <template #custom>
          <TimesCircleSVG class="w-12 fill_muted" />
        </template>
        <a
          v-tooltip="$t('label.remove')"
          href="#"
          class="py-1 px-3"
          @click.prevent="destroy"
        >
          <TimesCircleSVG class="w-12 fill_muted" />
        </a>
      </AtomIntegrationDisabledButton>
    </div>
  </div>
</template>

<script>
import Money from "@/components/helpers/Money.vue";
import Menus from "@/api/menus";
import EyeSVG from "@/assets/svg/icons/eye.svg";
import LineHeightSVG from "@/assets/svg/icons/line-height.svg";
import TimesCircleSVG from "@/assets/svg/icons/times-circle.svg";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { Toast } from "@/design-system";

export default {
  name: "Item",
  components: {
    Money,
    EyeSVG,
    LineHeightSVG,
    TimesCircleSVG,
    AtomIntegrationDisabledButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    categoryId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    hasMenuIntegration: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    destroy() {
      const callback = async () => {
        await Menus.removeItemsFromCategory(this.businessId, this.categoryId, [
          this.item.id,
        ]);
        this.$emit("deleted", this.item.id);
        this.$modal.hide("dialog");
        new Toast().create({
          type: "success",
          text: this.$t("message.record_removed"),
        });
      };
      destroyDialog(this, callback, { resourceName: this.item.name });
    },
  },
};
</script>
