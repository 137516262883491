<template>
  <div>
    <input
      :id="modifierItem"
      type="radio"
      class="modifier-item__input-hide"
      :name="modifierListId"
    />
    <label :for="modifierItem" class="modifier-item__label">
      <div class="row">
        <div class="col-auto">
          <span class="webview-selector--radio"></span>
        </div>
        <div class="col pl-0">
          {{ modifier.name }}
          <Money
            v-if="modifier.amount > 0"
            class="text-greyLight f-size-11"
            :amount="modifier.amount"
            :business-id="modifier.businessId"
          />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import Money from "@/components/helpers/Money.vue";

export default {
  name: "RadioSelector",
  components: { Money },
  props: {
    modifierListId: {
      type: String,
      required: true,
    },
    modifier: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modifierItem() {
      return this.modifierListId + "-" + this.modifier.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.webview-selector--radio {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border: solid 1px var(--color-brand-primary);
  background-color: var(--color-white);
  box-shadow: inset 0 0 0px var(--color-white);
  transition: all 0.2s ease-out;
}

.modifier-item__input-hide:checked
  + .modifier-item__label
  .webview-selector--radio {
  border: none;
  box-shadow: inset 0 0 0 7px var(--color-brand-primary),
    0 4px 6px rgba(96, 108, 220, 0.3);
}
</style>
