<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="d-flex align-items-center editInline relative">
    <InputMoney
      v-model="modifier.newAmount"
      data-test="inputAmount"
      :on-focus="true"
      :business-id="businessId"
      @keyup.native.enter="save"
      @keyup.native.esc="$emit('close')"
    />
    <a
      v-tooltip="{
        autoHide: false,
        trigger: 'click hover',
        content: $t('label.save'),
      }"
      href="#"
      data-test="btnSave"
      class="editInline__icon py-1 px-2"
      :class="{
        'btn-progress': loading,
      }"
      @click.prevent="save"
    >
      <CheckSVG class="w-13" />
    </a>
    <a
      v-tooltip="{
        autoHide: false,
        trigger: 'click hover',
        content: $t('label.cancel'),
      }"
      href="#"
      class="editInline__icon py-1 px-2"
      data-test="btnCancel"
      @click.prevent="$emit('close')"
    >
      <TimesSVG class="w-10" />
    </a>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import ModifierLists from "@/api/modifier-lists";
import TimesSVG from "@/assets/svg/icons/times.svg";
import CheckSVG from "@/assets/svg/icons/check.svg";
import { InputMoney, Toast } from "@/design-system";

export default {
  name: "OverrideModifierPrice",
  components: {
    InputMoney,
    TimesSVG,
    CheckSVG,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    modifierListId: {
      type: String,
      required: true,
    },
    modifier: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  created() {
    this.modifier.newAmount = this.modifier.amount;
  },
  methods: {
    async save() {
      this.loading = true;

      try {
        await ModifierLists.overrideModifierPrice(
          this.businessId,
          this.modifierListId,
          this.modifier.toUpdatePricePayload()
        );

        this.modifier.applyNewAmount();
        this.$emit("close");

        new Toast().create({
          type: "success",
          text: this.$t("message.record_updated"),
        });
      } catch (err) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
