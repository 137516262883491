import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import {
  IFetchPrintingGroupItemRequest,
  IFetchPrintingGroupItemContract,
  IFetchPrintingGroupItemResponse,
  IFetchPrintingGroupItemBodyParams,
} from "./types";

export class FetchPrintingGroupItemError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchPrintingGroupItemError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchPrintingGroupItem(
  request: IFetchPrintingGroupItemRequest
): Promise<IFetchPrintingGroupItemResponse> {
  let response: AxiosResponse<IFetchPrintingGroupItemContract>;

  try {
    const params: IFetchPrintingGroupItemBodyParams = {
      item_ids: request.itemIds,
    };

    response = await http.get(
      `/api/v4/businesses/${request.businessId}/items-by-ids`,
      {
        params,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchPrintingGroupItemError(error);
  }

  return response.data.data;
}
