<template>
  <div style="max-width: 740px">
    <div class="f-size-12 f-w-500 text-muted">{{ $t("label.name") }}:</div>
    <p class="mb-4">{{ entry.name }}</p>
    <div class="row">
      <div v-if="entry.displayName" class="col-sm mb-4">
        <div class="f-size-12 f-w-500 text-muted">
          {{ $t("label.display_name") }}:
        </div>
        <p>{{ entry.displayName }}</p>
      </div>
      <div v-if="entry.selectionType" class="col-sm mb-4">
        <div class="f-size-12 f-w-500 text-muted">{{ $t("label.type") }}:</div>
        <p data-test="type">
          {{ entry.selectionType | cfStringBeautify }}
        </p>
      </div>
      <div v-if="entry.source && entry.source.name" class="col-sm mb-4">
        <div class="f-size-12 f-w-500 text-muted">
          {{ $t("label.source") }}:
        </div>
        <p data-test="source">
          {{ entry.source.name }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm">
        <div class="f-size-12 f-w-500 text-muted">
          {{ $t("label.min_modifiers") }}:
        </div>
        <p class="mb-4">{{ entry.minSelectedModifiers }}</p>
      </div>
      <div class="col-sm">
        <div class="f-size-12 f-w-500 text-muted">
          {{ $t("label.max_modifiers") }}:
        </div>
        <p class="mb-4">{{ entry.maxSelectedModifiers }}</p>
      </div>
    </div>
    <div class="clearfix">
      <AtomIntegrationDisabledButton
        kind="default"
        class="d-inline-block"
        :enabled="!canEdit"
        :has-menu-integration="!canEdit"
        :label="$t('label.edit').toString()"
      >
        <button
          data-test="edit"
          class="btn btn-primary"
          @click.prevent="$emit('close')"
        >
          {{ $t("label.edit") }}
        </button>
      </AtomIntegrationDisabledButton>
      <router-link
        data-test="back"
        class="btn ml-2 btn-light"
        :to="{ name: 'modifier-lists.index' }"
      >
        {{ $t("label.back") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "ViewRecord",
  components: {
    AtomIntegrationDisabledButton,
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canEdit() {
      return (
        this.entry?.source?.isEditable &&
        this.entry?.source?.id === MenuManagementSourceByIdEnum.CHATFOOD
      );
    },
  },
};
</script>
