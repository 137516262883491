var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex bg-white justify-content-between border-top box-shadow-bottom pl-2"},[(!_vm.hasMenuIntegration)?_c('span',{staticClass:"pl-0 pt-3 pr-3 moveHandle"},[_c('LineHeightSVG')],1):_vm._e(),_c('div',{staticClass:"d-flex w-100 align-items-center justify-content-between minh-55"},[_c('span',{staticClass:"minw-200 w-100"},[_vm._v(_vm._s(_vm.modifier.name))]),(_vm.editMode)?_c('OverridePrice',{attrs:{"business-id":_vm.businessId,"modifier-list-id":_vm.modifierListId,"modifier":_vm.modifier},on:{"close":function($event){_vm.editMode = false}}}):_c('div',{staticClass:"editInline",attrs:{"data-test":"viewMode"}},[_c('a',{staticClass:"text-dark d-flex align-items-center justify-content-end text-decoration-none editInline__link p-2 p_right_0",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.editModeVerify.apply(null, arguments)}}},[_c('Money',{staticClass:"d-inline-block mr-2 editInline__text",attrs:{"amount":_vm.modifier.amount,"business-id":_vm.businessId,"data-test":"price"}}),_c('AtomIntegrationDisabledButton',{staticClass:"px-2",attrs:{"enabled":_vm.hasMenuIntegration,"has-menu-integration":_vm.hasMenuIntegration,"kind":"custom","label":_vm.$t('label.remove').toString()},scopedSlots:_vm._u([{key:"custom",fn:function(){return [_c('PenSVG',{staticClass:"w-10"})]},proxy:true}])},[_c('span',{staticClass:"editInline__icon px-2"},[_c('PenSVG',{staticClass:"w-10"})],1)])],1)]),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        autoHide: false,
        trigger: 'click hover',
        content: ("" + (_vm.$t('label.view'))),
      }),expression:"{\n        autoHide: false,\n        trigger: 'click hover',\n        content: `${$t('label.view')}`,\n      }"}],staticClass:"py-1 px-3",attrs:{"to":{
        name: 'items.edit',
        params: { itemId: _vm.modifier.id },
      },"target":"_blank"}},[_c('EyeSVG',{staticClass:"w-14 fill_muted"})],1),_c('AtomIntegrationDisabledButton',{staticClass:"ml-2",attrs:{"enabled":_vm.hasMenuIntegration,"has-menu-integration":_vm.hasMenuIntegration,"kind":"custom","label":_vm.$t('label.remove').toString()},scopedSlots:_vm._u([{key:"custom",fn:function(){return [_c('TimesCircleSVG',{staticClass:"w-12 fill_muted"})]},proxy:true}])},[_c('a',{staticClass:"py-1 px-3",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.destroy.apply(null, arguments)}}},[_c('TimesCircleSVG',{staticClass:"w-12 fill_muted"})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }