import { IRepoErrors } from "../../errors";
import { http } from "../../http";

export class UpdateMenuIconError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateMenuIcon");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function uploadMenuIcon(
  businessId: string,
  menuId: string,
  file: File
): Promise<string> {
  const data = new FormData();
  data.append("icon", file);

  try {
    const response = await http.post(
      `/api/v3/businesses/${businessId}/menus/${menuId}/upload-icon`,
      data
    );

    return response.data.icon_url;
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateMenuIconError(e);
  }
}
