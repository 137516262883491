<template>
  <div class="d-inline-block position-relative">
    <input
      :id="_uid"
      v-model="model"
      type="radio"
      :value="checkedValue"
      :name="name"
      @change="changed"
    />
    <label
      class="btn btn-outline-dark btn-outline--custom mb-0"
      :for="_uid"
      :class="{
        active: model === checkedValue,
      }"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    checkedValue: {
      type: [String, Boolean],
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  data: () => ({
    model: null,
  }),
  watch: {
    value: {
      handler(newValue) {
        this.model = this.checkedValue === newValue ? newValue : null;
      },
      immediate: true,
    },
  },
  methods: {
    changed() {
      this.$emit("input", this.checkedValue);
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  position: absolute;
  top: 0;
  opacity: 0;
}

input[type="radio"]:focus + label {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}
</style>
