<template>
  <div class="clearfix">
    <div
      class="py-2 px-4 border-bottom minh-70 d-flex align-items-center w-100"
    >
      <img
        v-if="business.logo"
        :src="imgURL"
        :alt="business.name"
        class="logoCustomer"
      />
      {{ business.name }}
    </div>
    <div class="p-4">
      <h5 class="mb-3">{{ $t("label.create_modifier_list") }}</h5>
      <div class="p-4 border rounded">
        <div style="max-width: 740px">
          <Form
            data-test="form"
            :business-id="businessId"
            @saved="recordCreated"
            @close="close"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/modifier-lists/Form.vue";
import { getBusinessById } from "@/utils/mixins";
import { imageURLBuilder } from "@/v2/util/image-url-builder";

export default {
  name: "ModifierListsCreate",
  components: {
    Form,
  },
  mixins: [getBusinessById],
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    imgURL() {
      return imageURLBuilder(this.business.logo, 100, 100);
    },
  },
  methods: {
    async recordCreated(record) {
      this.$analytics.trackEvent("Modifier list created", {
        business_id: this.businessId,
        business_name: this.business.name,
        name: record.name,
        event_category: "menu_builder",
      });

      this.$router.push({
        name: "modifier-lists.edit",
        params: {
          businessId: this.businessId,
          modifierListId: record.id,
        },
      });
    },
    close() {
      this.$router.push({
        name: "modifier-lists.index",
      });
    },
  },
};
</script>
