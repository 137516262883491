<template>
  <div class="item">
    <span>{{ availability.outletName }}</span>
    <AtomSwitcher :checked="isAvailable" :on-change="toggleAvailability" />
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import Menus from "@/api/menus";
import { AtomSwitcher } from "@/v2/new-design-system";
import { Toast } from "@/design-system";

export default {
  name: "Item",
  components: {
    AtomSwitcher,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    categoryId: {
      type: String,
      required: true,
    },
    availability: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isAvailable: false,
  }),
  watch: {
    availability: {
      handler(val) {
        this.isAvailable = val.isAvailable;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async request() {
      return this.isAvailable
        ? Menus.makeCategoryVisibleToOutlet(
            this.businessId,
            this.categoryId,
            this.availability.outletId
          )
        : Menus.hideCategoryFromOutlet(
            this.businessId,
            this.categoryId,
            this.availability.outletId
          );
    },
    async toggleAvailability() {
      this.isAvailable = !this.isAvailable;

      try {
        await this.request();
        this.availability.isAvailable = this.isAvailable;
        new Toast().create({
          type: "success",
          text: this.$t("message.record_updated"),
        });
      } catch (e) {
        this.isAvailable = !this.isAvailable;
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-21);
  padding: var(--space-14) 0;

  &:not(:last-child) {
    border-bottom: solid 1px var(--color-cloudy);
  }
}
</style>
