<template>
  <div class="device-preview">
    <div class="device-preview__screen">
      <div class="device-preview__view">
        <h4 class="bg-white pt-3 pb-1 px-3 m-0 f-w-500">
          {{ businessName }} {{ $t("label.menu") }}
        </h4>
        <Menu :categories="categories" @openItem="openItem" />
        <Item
          class="device-preview__view device-preview__view--right"
          :class="{ 'device-preview__view--toCenter': showItem }"
          :item="item"
          @closeItem="closeItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./components/menu/Index.vue";
import Item from "./components/item/Index.vue";
import items from "@/api/items";
import ItemConstructor from "@/constructors/Item";

export default {
  name: "PreviewMenu",
  components: {
    Menu,
    Item,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    showItem: false,
    item: new ItemConstructor(),
  }),
  methods: {
    async openItem(itemId) {
      this.showItem = true;
      this.item = await items.find(this.businessId, itemId, {
        include: "modifiers.items",
      });
    },
    closeItem() {
      this.showItem = false;
      this.item = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.device-preview {
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  width: 350px;
  margin: 0 auto;
  font-family: "Nunito Sans", sans-serif;
  color: var(--color-black);
  border-radius: 38px;
  padding: 30px 15px;
  background: var(--color-white);
  box-shadow: 0 2px 5px rgba(0, 21, 64, 0.14), 0 4px 8px rgba(62, 62, 64, 0.02);

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 140px;
    width: 8px;
    z-index: -1;
    background: var(--color-white);
  }

  &:before {
    right: 100%;
    height: 50px;
    border-radius: 10px 0 0 10px;
    box-shadow: -1px 4px 3px rgba(0, 21, 64, 0.1), 0px 75px 0 var(--color-white),
      -1px 79px 3px rgba(0, 21, 64, 0.1);
  }

  &:after {
    left: 100%;
    height: 70px;
    border-radius: 0 10px 10px 0;
    box-shadow: 1px 4px 3px rgba(0, 21, 64, 0.1);
  }

  &__screen {
    position: relative;
    flex: 1 0 100%;
    border-radius: 8px;
    border: solid 2px #f3f3f3;
    background: var(--color-white);
    overflow: hidden;
  }

  &__view {
    width: 100%;
    height: 630px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: var(--color-white-1);
    transition: all 0.2s ease-out;

    &--right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      box-shadow: -2px 0 15px rgba(var(--color-black), 0);
      transform: translate(100%, 0);
    }

    &--toCenter {
      box-shadow: -2px 0 15px rgba(var(--color-black), 0.1);
      transform: translate(0, 0);
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color-white);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #eaeaea;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}

@media (max-width: 500px) {
  .device-preview {
    width: 100%;
    padding: 0;
    border-radius: 0;
    box-shadow: none;

    &:before,
    &:after {
      display: none;
    }

    &__screen {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }

    &__view {
      height: 530px;
      border-radius: 0;
    }
  }
}
</style>
