import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import {
  IFetchPrintingGroupsRequest,
  IFetchPrintingGroupsResponse,
  IFetchPrintingGroupsContract,
} from "./types";

export class FetchPrintingGroupsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchPrintingGroupsError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchPrintingGroups(
  request: IFetchPrintingGroupsRequest
): Promise<IFetchPrintingGroupsResponse> {
  let response: AxiosResponse<IFetchPrintingGroupsContract>;

  try {
    response = await http.get(
      `/printing/business/${request.businessId}/groups`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchPrintingGroupsError(error);
  }

  return response.data;
}
