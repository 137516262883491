<template>
  <li
    class="d-flex flex-wrap align-items-center justify-content-between minh-35 pr-3 pt-2 f-size-12"
  >
    {{ modifier.name }}
    <Money
      :amount="modifier.amount"
      :business-id="businessId"
      data-test="price"
    />
  </li>
</template>

<script>
import Money from "@/components/helpers/Money.vue";

export default {
  name: "Modifier",
  components: { Money },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    modifier: {
      type: Object,
      required: true,
    },
  },
};
</script>
