<template>
  <LoadingZone :loading="loading" class="p-4">
    <h5 class="text-center mb-4" data-test="title">
      <span class="bold">{{ $t("message.add_items_to") }} </span>
      <strong class="text-primary"> {{ modifierList.name }}</strong>
    </h5>
    <input
      v-if="availableItems.length"
      v-model="search"
      type="text"
      data-test="search"
      autofocus="autofocus"
      class="form-control mb-3"
      :placeholder="
        $t('message.search_for_x_followed_by_ellipses', {
          search: $t('label.item'),
        })
      "
    />
    <ul class="p-0 m-0" style="max-height: calc(100vh - 320px); overflow: auto">
      <li
        v-for="(item, index) in filteredItems"
        :key="item.id"
        data-test="item"
        class="border-bottom"
      >
        <div class="customCheckbox">
          <input
            :id="index"
            v-model="addItems"
            type="checkbox"
            :value="item"
            data-test="checkbox"
            class="customCheckbox__input"
          />
          <label :for="index" class="customCheckbox__text py-2">
            <span class="customCheckbox__square"></span>
            <div class="row m-0">
              <div class="col p-0">
                <span :title="createdAtLabel(item.createdAt)">
                  {{ item.name }}
                </span>
              </div>
              <div class="col-auto">
                <Money
                  tag="small"
                  :amount="item.amount"
                  :business-id="businessId"
                />
              </div>
            </div>
          </label>
        </div>
      </li>
    </ul>

    <div
      v-if="availableItems.length && !filteredItems.length"
      class="my-5 text-center"
    >
      {{ $t("message.there_is_no_item_matching") }}
    </div>

    <div v-if="!availableItems.length" class="my-5">
      <p class="text-center">
        {{ $t("message.there_is_no_item_attached") }}
      </p>
      <p class="text-center">
        <router-link
          class="btn btn-dark"
          data-test="btn-create"
          :to="{ name: 'items.create' }"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("label.create_item") }}
        </router-link>
      </p>
    </div>
    <div class="clearfix mt-4">
      <button
        class="btn btn-light"
        data-test="btn-cancel"
        @click="$emit('close')"
      >
        {{ $t("label.cancel") }}
      </button>
      <button
        data-test="add-items"
        class="btn btn-primary float-right"
        :class="{ 'btn-progress': adding }"
        :disabled="!addItems.length"
        @click.prevent="save"
      >
        {{ $tc("label.add_x_items", addItems.length) }}
      </button>
    </div>
  </LoadingZone>
</template>

<script>
import Items from "@/api/items";
import ModifierLists from "@/api/modifier-lists";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import Money from "@/components/helpers/Money.vue";
import { isEmpty, is, prop, sortWith, ascend, pluck } from "ramda";
import { mapGetters } from "vuex";
import { date } from "@/utils/filters";
import { Toast } from "@/design-system";

export default {
  name: "ItemsList",
  components: {
    LoadingZone,
    Money,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    modifierList: {
      type: Object,
      required: true,
    },
    itemsAdded: {
      type: Function,
      default: () => {},
    },
    excludeItems: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    search: "",
    loading: false,
    adding: false,
    items: [],
    addItems: [],
  }),
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    business() {
      return Object.values(this.businesses).find(
        (obj) => obj.id === this.businessId
      );
    },
    availableItems() {
      if (!is(Array, this.excludeItems)) return this.items;

      return this.items.filter((obj) => !this.excludeItems.includes(obj.id));
    },
    filteredItems() {
      if (isEmpty(this.search)) return this.availableItems;

      const query = this.search.trim().toLowerCase();
      return this.availableItems.filter((obj) =>
        obj.name.toLowerCase().includes(query)
      );
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    createdAtLabel(createdAt) {
      if (!createdAt) return;

      return `${this.$t("label.created_at")}: ${date(
        createdAt,
        "MMM DD, YYYY - h:mm A"
      )}`;
    },
    async fetch() {
      this.loading = true;

      const res = await Items.get(this.businessId, {
        is_modifier: 0,
        limit: 9999,
        source: this.modifierList.source.id,
      });

      this.items = sortWith([ascend(prop("name"))], res.data);

      this.loading = false;
    },
    async save() {
      this.adding = true;

      try {
        await ModifierLists.attachItems(
          this.businessId,
          this.modifierList.id,
          pluck("id", this.addItems)
        );

        this.$analytics.trackEvent("Modifier list attached to item", {
          business_id: this.businessId,
          business_name: this.business.name,
          event_category: "menu_builder",
        });

        this.itemsAdded(this.addItems);
        this.$emit("close");

        new Toast().create({
          type: "success",
          text: this.$t("message.records_linked"),
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.adding = false;
      }
    },
  },
};
</script>
