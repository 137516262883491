<template>
  <div class="mt-4 pb-2 bg-white box-shadow-1">
    <div class="row pt-4 px-3 mb-2">
      <div class="col">
        <h5 class="m-0">{{ modifierList.displayName }}</h5>
        <small
          v-if="modifierList.minSelectedModifiers > 0"
          class="f-size-11 f-w-500 d-block text-greyLight"
        >
          {{ $t("label.required") }}
        </small>
      </div>
      <div class="col-auto f-w-600 text-greyLight f-size-11">
        Max:
        {{ modifierList.maxSelectedModifiers }}
      </div>
    </div>
    <div
      v-for="(modifier, index) in modifierList.modifiers"
      :key="index"
      class="modifier-item"
    >
      <component
        :is="selectorType"
        :modifier-list-id="modifierList.id"
        :modifier="modifier"
        data-test="selector-component"
      />
    </div>
  </div>
</template>

<script>
import CheckboxSelector from "./selectors/Checkbox.vue";
import RadioSelector from "./selectors/Radio.vue";
import MultipleSelector from "./selectors/Multiple.vue";

export default {
  name: "ModifierList",
  components: {
    CheckboxSelector,
    RadioSelector,
    MultipleSelector,
  },
  props: {
    modifierList: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectorType() {
      if (this.modifierList.selectionType === "multiple") {
        return "MultipleSelector";
      }
      return this.modifierList.minSelectedModifiers === 1 &&
        this.modifierList.maxSelectedModifiers === 1
        ? "RadioSelector"
        : "CheckboxSelector";
    },
  },
};
</script>

<style lang="scss">
.modifier-item {
  position: relative;

  &__label {
    position: relative;
    display: block;
    min-height: 44px;
    margin: 0;
    padding: 0.75rem 1rem;
    cursor: pointer;
  }

  &__input-hide {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
</style>
