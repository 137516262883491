<template>
  <div class="menu-edit">
    <MolBackNav
      :route="{ name: 'menus.index', params: { businessId } }"
      :label="$t('label.menus')"
      force-route
    />
    <div class="menu-edit__content">
      <AtomLoading v-if="isLoading" floating />
      <template v-else>
        <div class="menu-edit__header">
          <div>
            <div class="menu-edit__title">
              <AtomText :type="AtomTextTypeEnum.H2">
                {{ entry.name }}
              </AtomText>
              <div v-if="!entry.isDefault" data-test="is-not-default">
                <AtomButton
                  v-if="isMenuLive"
                  data-test="make-default-button"
                  :type="AtomButtonTypeEnum.SECONDARY"
                  :size="AtomButtonSizeEnum.SMALL"
                  :on-click="makeMenuDefault"
                >
                  {{
                    $t(
                      "module.menu_details_page.make_menu_default.button_make_default"
                    )
                  }}
                </AtomButton>
                <OrgInfoLockButton v-else data-test="info-default-button">
                  <template slot="lock-info">
                    {{
                      $t(
                        "module.menu_details_page.make_menu_default.make_menu_default_disabled_tooltip"
                      )
                    }}
                  </template>
                  <template slot="button-text">
                    {{
                      $t(
                        "module.menu_details_page.make_menu_default.button_make_default"
                      )
                    }}
                  </template>
                </OrgInfoLockButton>
              </div>
              <span v-else data-test="is-default" class="menu-edit__label">
                {{ $t("module.menu_details_page.default") }}
              </span>
            </div>
            <AtomText
              v-if="entry.summary"
              class="menu-edit__notes"
              :color="AtomTextColorEnum.RAINY"
            >
              {{ entry.summary }}
            </AtomText>
          </div>
          <div class="menu-edit__header-actions">
            <AtomButton
              :type="AtomButtonTypeEnum.SECONDARY"
              :size="AtomButtonSizeEnum.SMALL"
              :on-click="openConnectionsMenuModal"
            >
              {{ $t("module.menu_details_page.connections_details_button") }}
            </AtomButton>
            <AtomButton
              data-test="open-edit-menu-modal"
              :is-disabled="!canEditMenu"
              :type="AtomButtonTypeEnum.SECONDARY"
              :size="AtomButtonSizeEnum.SMALL"
              :on-click="openCreateMenuModal"
            >
              {{ $t("module.menu_details_page.edit_menu_details_button") }}
            </AtomButton>
          </div>
        </div>
        <EditImages
          v-if="entry.name && canEditImages"
          class="menu-edit__images"
          :business-id="businessId"
          :menu-id="menuId"
          :icon="entry.icon"
          :image="entry.image"
        />
        <div class="menu-edit__table">
          <Categories
            class="menu-edit__table-categories"
            :business-id="businessId"
            :menu-id="menuId"
            :categories="categories"
            :has-menu-integration="!canEditMenu"
            :source-id="entry.source.id"
            @categoryDeleted="categoryDeleted"
            @reordered="cateoriesReordered"
          />
          <div class="menu-edit__table-preview">
            <AtomText
              :type="AtomTextTypeEnum.BODY_BOLD"
              class="menu-edit__table-preview-title"
            >
              {{ $t("label.menu_preview") }}
            </AtomText>
            <PreviewMenu
              :business-id="businessId"
              :business-name="business.name"
              :categories="categories"
            />
          </div>
        </div>
        <ConnectionMenuModal
          v-if="showConnectionsMenuModal"
          :business-id="businessId"
          :menu-id="menuId"
          :menu-name="entry.name"
          :selected-services="selectedOutlets"
          :source-id="entry.source.id"
          :source-is-editable="entry.source.isEditable"
          :source-is-integration="entry.source.isIntegration"
          :on-close="() => (showConnectionsMenuModal = false)"
        />
        <EditMenuModal
          v-if="showEditMenuModal"
          :business-id="businessId"
          :menu-name="entry.name"
          :menu-notes="entry.notes"
          :menu-summary="entry.summary"
          :menu-id="menuId"
          :menu-hide-snoozed-items="entry.hideSnoozedItems"
          :on-save="menuUpdated"
          :on-close="() => (showEditMenuModal = false)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Menus from "@/api/menus";
import Categories from "@/components/menus/Categories.vue";
import PreviewMenu from "@/modules/menu-preview/Index.vue";
import { getBusinessById } from "@/utils/mixins";
import { propOr } from "ramda";
import { setMenuDefault } from "@/v2/repo/menu/set-menu-default";
import {
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  MolBackNav,
  OrgInfoLockButton,
} from "@/v2/new-design-system";
import { fetchMenusFromBusiness } from "@/v2/repo/fetch-menus-from-business";
import { EditMenuModal } from "@/v2/module/menu/edit-menu-modal";
import { EditImages } from "@/v2/module/menu/edit-images";
import { ConnectionMenuModal } from "@/v2/module/menu/connection-menu-modal";
import { mapGetters } from "vuex";
import { EDIT_MENU_IMAGES } from "@/utils/enums/permissions";
import { Toast } from "@/design-system";

export default {
  name: "MenusEdit",
  components: {
    AtomLoading,
    Categories,
    PreviewMenu,
    AtomButton,
    AtomText,
    MolBackNav,
    EditMenuModal,
    EditImages,
    ConnectionMenuModal,
    OrgInfoLockButton,
  },
  mixins: [getBusinessById],
  props: {
    businessId: {
      type: String,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    selectedOutlet: [],
    isLoading: false,
    entry: {},
    showEditMenuModal: false,
    showConnectionsMenuModal: false,
    AtomButtonTypeEnum: AtomButtonTypeEnum,
    AtomButtonSizeEnum: AtomButtonSizeEnum,
    AtomTextTypeEnum: AtomTextTypeEnum,
    AtomTextColorEnum: AtomTextColorEnum,
  }),
  computed: {
    ...mapGetters({
      permissions: "auth/getPermissions",
    }),
    isMenuLive() {
      return this.entry.state === "live";
    },
    canEditMenu() {
      return this.entry?.source?.isEditable;
    },
    categories() {
      return propOr([], "categories", this.entry);
    },
    canEditImages() {
      return this.permissions.includes(EDIT_MENU_IMAGES);
    },
  },
  created() {
    this.fetch();
    this.getConnections();
  },
  methods: {
    async getConnections() {
      let response;

      try {
        response = await fetchMenusFromBusiness(
          this.businessId,
          1,
          this.entry.name
        );
      } catch (e) {
        this.showConnectionsMenuModal = false;

        new Toast().create({
          type: "error",
          title: this.$t(
            "module.menu_details_page.make_menu_default.toast_error_title"
          ),
          text: this.$t(
            "module.menu_details_page.make_menu_default.toast_error_message"
          ),
        });
      }
      if (!response) return;

      this.selectedOutlets = response.data
        .filter((data) => data.id === this.menuId)
        .flatMap(({ attachedOutlets }) =>
          attachedOutlets.map((outlet) =>
            outlet.orderingServices.flatMap(
              (service) => `${outlet.outletId}:${service}`
            )
          )
        )
        .flat();
    },
    async makeMenuDefault() {
      let response = false;

      try {
        response = await setMenuDefault(this.businessId, this.menuId);
      } catch (e) {
        new Toast().create({
          type: "error",
          title: this.$t(
            "module.menu_details_page.make_menu_default.toast_error_title"
          ),
          text: this.$t(
            "module.menu_details_page.make_menu_default.toast_error_message"
          ),
        });
        return;
      }

      if (!response) {
        return;
      }

      this.entry.isDefault = response;

      new Toast().create({
        type: "success",
        title: this.$t("label.success"),
        text: this.$t(
          "module.menu_details_page.make_menu_default.toast_sucess_message"
        ),
      });
    },
    openCreateMenuModal() {
      this.showEditMenuModal = true;
    },
    async openConnectionsMenuModal() {
      await this.getConnections();
      this.showConnectionsMenuModal = true;
    },
    cateoriesReordered(categories) {
      this.entry.categories = categories;
    },
    async fetch() {
      this.isLoading = true;
      this.entry = await Menus.find(this.businessId, this.menuId, {
        include: "categories,categories.items,categories.outletVisibilities",
      });
      this.isLoading = false;
    },
    menuUpdated(entry) {
      this.entry.name = entry.name;
      this.entry.notes = entry.notes ?? "";
      this.entry.summary = entry.summary ?? "";
      this.entry.hideSnoozedItems = entry.hideSnoozedItems;
      this.showEditMenuModal = false;
    },
    categoryDeleted(categoryId) {
      this.entry.categories = this.entry.categories.filter(
        (obj) => obj.id !== categoryId
      );
    },
  },
};
</script>

<style scoped lang="scss">
.menu-edit {
  padding: var(--space-35) var(--space-28);

  @media (max-width: 676px) {
    padding: var(--space-21);
  }

  &__content {
    position: relative;
    min-height: 25rem;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--space-14);
    justify-content: space-between;
    margin-bottom: var(--space-21);

    &-actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: var(--space-14);
    }
  }

  &__images {
    margin-bottom: var(--space-21);
  }

  &__notes {
    display: inline-block;
    max-width: 37.5rem;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: var(--space-21);
    margin: var(--space-7) 0 var(--space-3);
  }

  &__label {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-midnight);
    padding: var(--space-3) var(--space-7);
    border-radius: var(--border-radius-1);
    background-color: var(--color-powder);
  }

  &__table {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-21);
    justify-content: space-between;
    border-radius: var(--border-radius-1);
    border: solid 1px var(--color-cloudy);

    &-categories {
      flex: 1 0 34rem;
      padding: var(--space-14);
    }

    &-preview {
      flex: 1 0 27rem;
      padding: var(--space-21) var(--space-35);
      background: var(--color-powder);

      &-title {
        display: block;
        text-align: center;
        margin-bottom: var(--space-14);
      }
    }
  }
}
</style>
